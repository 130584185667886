import dayjs from "dayjs";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";

import { PropertyTemporary, PropertyType } from "../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../module/custom_api_factory";
import { CircularProgressWithLabel } from "../../../../../scripts/components/renewal_v1/property/circular_progress";
import { BaseTemplate } from "../../../../../scripts/components/renewal_v1/template/BaseTemplate";
import { AppEventTokenEnum, sendEventToAppMeasurementTool } from "../../../../../utilities/capacitor_logic";
import { castFieldInputs } from "../../../../../utilities/cast_field_inputs";
import { deleteUnacceptableKeys } from "../../../../../utilities/delete_unacceptable_keys";
import {
  formDataMappingToLeaseContract,
  formDataMappingToLeaseContractForUpdate,
  formDataMappingToProperty,
  handleFirstRegistration,
  postProperty,
} from "../../../../../utilities/renewal_v1/property_management";
import {
  getStorageConsiderationPropertyRegistrationState,
  removeStorageConsiderationPropertyRegistrationState,
} from "../../../../../utilities/storage";
import {
  ConsiderationPropertyRegistrationState,
  PropertyManagementListSnackbarState,
  UserDataFirstRegisteredStatusState,
  useRequestInitialLoading,
} from "../../../../../view_models/atoms";
import { Loading } from "../../../../loading";
import { DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA, DateFieldNames, DoubleFieldNames } from "../const";
import { DraftPropertyModal } from "../draft_modal";
import { DraftPropertyReuseModal } from "../draft_modal_reuse";
import { AddNewPropertyFormSchema } from "../type";

import AddNewConsiderationPropertyBasicPage from "./basic";
import AddNewConsiderationPropertyCashFlowPage from "./cashflow";
import AddNewConsiderationPropertyLoanPage from "./loan";

const AddNewConsiderationPropertyPage: React.FC = () => {
  const isSubmittingRef = React.useRef(false);
  const history = useHistory();
  const methods = useForm({ mode: "onChange" });

  const [currentStep, setCurrentStep] = React.useState(0);

  const requestInitialLoading = useRequestInitialLoading();
  // 物件一覧ページでのスナックバー表示用
  const setManagementListSnackBarState = useSetRecoilState(PropertyManagementListSnackbarState);

  const [isOpenReuseTempoModal, setIsOpenReuseTempoModal] = React.useState(false);
  const [isClickedPageBack, setIsClickedPageBack] = React.useState(false);
  const setUserDataFirstRegisteredStatus = useSetRecoilState(UserDataFirstRegisteredStatusState);
  // LocalStorage
  const [formPersistData, setFormPersistData] = useRecoilState(ConsiderationPropertyRegistrationState);
  const localStorageFormData = getStorageConsiderationPropertyRegistrationState();
  // フォームの状態
  const [formData, setFormData] = React.useState<AddNewPropertyFormSchema>(formPersistData);
  // 一時保存情報の状態
  const [propertyTempInfo, setPropertyTempInfo] = React.useState<PropertyTemporary>(null);

  const onClickPageBack = React.useCallback(() => {
    window.scrollTo(0, 0);
    setIsClickedPageBack(true);
  }, [setIsClickedPageBack]);

  const onClickPageNext = React.useCallback(() => {
    window.scrollTo(0, 0);
    setCurrentStep((prev) => prev + 1);
  }, [setCurrentStep]);

  /**
   * 関数定義
   */

  // 一時保存関数
  const saveTemporary = async () => {
    await methods.trigger();

    // DBに一時保存データがあるかどうかフラグを保存
    const fp = PropallyApiFactory();
    fp.v1PropertyTemporaryPost({ withCredentials: true }).then(() => {
      console.log("一時保存しました");
    });
    setFormPersistData({ ...formData, ...methods.getValues() });
  };
  // ==== 一時保存情報の利用 ====
  const onClickReuseTempo = async () => {
    setIsOpenReuseTempoModal(false);
  };

  // ==== 物件一時保存情報削除 ====
  const deletePropertyTemporary = async () => {
    const fp = PropallyApiFactory();
    try {
      const response = await fp.v1PropertyTemporaryDelete({ withCredentials: true });
      if (response.status === 200) {
        // フォームのデフォルト値を削除
        setFormData(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
        setFormPersistData(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
        // ローカルストレージの削除
        removeStorageConsiderationPropertyRegistrationState();
        // フォームの値をリセット
        methods.reset(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
        // 一時保存情報の削除
        setPropertyTempInfo(null);
      }
    } catch (e) {
      console.error(e);
    }
    setIsOpenReuseTempoModal(false);
  };
  const saveTemporaryAndGoBack = async () => {
    await saveTemporary();
    history.push("/management");
  };
  const cancelSaveTemporaryAndGoBack = async () => {
    await deletePropertyTemporary();
    history.push("/management");
  };

  // 各ページでの処理
  const validate = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      console.log("formData in validate", formData);
      const saveFormData = {
        ...formData,
        ...methods.getValues(),
      };
      console.log("saveFormData", saveFormData);
      console.log("image", saveFormData.property_image);
      setFormData(saveFormData);
      // LocalStorage
      setFormPersistData(saveFormData);
      console.log("valid");
    }
    return isValid;
  };
  const onSubmit = React.useCallback(async () => {
    console.log("submit");
    // 二重送信防止
    if (isSubmittingRef.current) return;
    isSubmittingRef.current = true;

    // LocalStorage
    setFormPersistData(formPersistData);

    console.log("formData on submit", formData);
    console.log("persistData on submit", formPersistData);

    console.log("methods.getValues()", methods.getValues());
    let data = {
      ...methods.getValues(),
      ...formData,
    };
    console.log("data", data);

    // cut off the undefined/non-entered values
    data = deleteUnacceptableKeys(data);

    // cast date & double fields
    data = castFieldInputs(data, { dateFieldNames: DateFieldNames, doubleFieldNames: DoubleFieldNames });
    console.log("processd data", data);

    // Property型に変換
    const property = formDataMappingToProperty(data, PropertyType.NUMBER_1);

    console.log("property", property);

    // LeaseContract型に変換
    const lease_contract = formDataMappingToLeaseContract(data);

    // PropertyBuilder型に変換
    const property_builder = formDataMappingToLeaseContractForUpdate(data, property, lease_contract);

    try {
      const postResponse = await postProperty(property_builder);
      console.log("postResponse", postResponse);

      await handleFirstRegistration(setUserDataFirstRegisteredStatus);
    } catch (e) {
      console.error(e);
      // 物件一覧ページでのスナックバー表示用
      setManagementListSnackBarState({ isOpen: true, message: "物件を追加できませんでした", status: "error" });
      history.push("/management");
      return;
    }
    // データの初期化
    setFormData(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
    setFormPersistData(DEFAULT_ADD_NEW_PROPERTY_FORM_SCHEMA);
    // ローカルストレージの物件情報の削除
    removeStorageConsiderationPropertyRegistrationState();
    console.log("success");
    // 物件一覧ページでのスナックバー表示用
    setManagementListSnackBarState({ isOpen: true, message: "物件を追加しました", status: "success" });
    requestInitialLoading();
    sendEventToAppMeasurementTool(AppEventTokenEnum.ConsiderationPropertyCompleted);
    history.push("/management");

    isSubmittingRef.current = false; // 送信完了後、二重送信管理フラグをリセット
    console.log("complete");
  }, [formData]);

  const steps = [
    <AddNewConsiderationPropertyBasicPage key="basic" onNext={onClickPageNext} validate={validate} />,
    <AddNewConsiderationPropertyLoanPage
      key="loan"
      onNext={onClickPageNext}
      onBack={() => setCurrentStep((prev) => prev - 1)}
      validate={validate}
    />,
    <AddNewConsiderationPropertyCashFlowPage
      key="cashflow"
      onBack={() => setCurrentStep((prev) => prev - 1)}
      validate={validate}
    />,
  ];
  const stepText = React.useMemo(
    () => [
      {
        title: "物件概要の入力",
        message: "物件に関する情報を記入してください",
      },
      {
        title: "価格・ローン",
        message: "価格・ローンに関する情報を記入してください",
      },
      {
        title: "月間収支予定",
        message: "月間収支に関する情報を記入してください",
      },
    ],
    []
  );

  // ローカルストレージのデータがある場合は確認モーダルの表示
  React.useEffect(() => {
    const fp = PropallyApiFactory();
    const fetchPropertyRegistrationTemporary = async () => {
      try {
        const response = await fp.v1PropertyTemporaryGet({ withCredentials: true });
        if (response.status === 200) {
          if (response.data?.updated_at && localStorageFormData !== null) {
            setIsOpenReuseTempoModal(true);
            setPropertyTempInfo(response.data);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchPropertyRegistrationTemporary();
  }, []);

  // ローカルストレージのデータをフォームにセットしてレンダリングをする前にモーダルで確認
  if (isOpenReuseTempoModal) {
    return (
      <BaseTemplate headerTitle="物件登録" onBack={onClickPageBack} isDense>
        <Loading />
        <DraftPropertyReuseModal
          onClose={() => setIsOpenReuseTempoModal(false)}
          onClickUse={onClickReuseTempo}
          onClickDelete={deletePropertyTemporary}
          lastSavedDate={dayjs(propertyTempInfo?.updated_at).format("YYYY年MM月DD日 HH:mm")}
        />
      </BaseTemplate>
    );
  }

  return (
    <BaseTemplate headerTitle="物件登録" onBack={onClickPageBack} isDense>
      <CircularProgressWithLabel
        value={currentStep + 1}
        stepName={stepText[currentStep].title}
        stepMessage={stepText[currentStep].message}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>{steps[currentStep]}</form>
      </FormProvider>
      {isClickedPageBack ? (
        <DraftPropertyModal
          onClose={() => setIsClickedPageBack(false)}
          onClickSave={saveTemporaryAndGoBack}
          onClickCancel={cancelSaveTemporaryAndGoBack}
        />
      ) : null}
    </BaseTemplate>
  );
};

export default AddNewConsiderationPropertyPage;
