import { useSnackbar } from "notistack";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { PropallyApiFactory } from "../module/custom_api_factory";
import { sendEventToAppMeasurementTool, isFirstAccess, AppEventTokenEnum } from "../utilities/capacitor_logic";
import { clearStorage } from "../utilities/storage";
import { UserState, SessionState } from "../view_models/atoms";

export const useLogout = (): {
  onClickLogout: () => Promise<void>;
} => {
  const fp = PropallyApiFactory();

  const user = useRecoilValue(UserState);
  const setSession = useSetRecoilState(SessionState);

  const { enqueueSnackbar } = useSnackbar();

  const logout = async (): Promise<void> => {
    const response = await fp.v1LogoutPost({ withCredentials: true });

    if (response.status === 200) {
      setSession({
        logged_in: false,
      });
      clearStorage();
      window.location.reload();
    } else {
      enqueueSnackbar("ネットワークエラーです。しばらくたってからお試しください");
    }
  };

  const onClickLogout = async () => {
    sendEventToAppMeasurementTool(
      isFirstAccess(user?.created_at)
        ? AppEventTokenEnum.SignoutFirstAccess
        : AppEventTokenEnum.SignoutSecondOnwardsAccess
    );
    await logout();
  };

  return { onClickLogout };
};
