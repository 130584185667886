import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import dayjs, { Dayjs } from "dayjs";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useRecoilState } from "recoil";

import { MonthRangeSelector } from "../../../scripts/components/renewal_v1/month_range_selector";
import { MultiSelect } from "../../../scripts/components/renewal_v1/multi_select";
import { BaseTemplate } from "../../../scripts/components/renewal_v1/template/BaseTemplate";
import { useDevice } from "../../../utilities/sp/use_device";
import { SelectedStartingMonth, SelectedEndingMonth } from "../../../view_models/atoms";
import { SelectedPropertyIds } from "../../../view_models/atoms";
import { OwnedProperties } from "../../../view_models/property_selectors";
import { Loading } from "../../loading";

import { useBalanceSheetData } from "./hooks/useBalanceSheet";
import { useProfitAndLossSheetData } from "./hooks/useProfitAndLoss";
import { SheetTable } from "./sheet_table";

enum TabType {
  BALANCE = 0,
  PROFIT_AND_LOSS = 1,
}

export const BalancePage: FC = () => {
  const history = useHistory();
  const { isMobile } = useDevice();
  const [selectedPropertyIds, setSelectedPropertyIds] = useRecoilState(SelectedPropertyIds);
  const [selectedStartingMonth, setSelectedStartingYear] = useRecoilState(SelectedStartingMonth);
  const [selectedEndingMonth, setSelectedEndingYear] = useRecoilState(SelectedEndingMonth);
  const selected_starting_month = useRecoilValue(SelectedStartingMonth);
  const propertyOptions = useRecoilValue(OwnedProperties).map((property) => {
    return { id: property.id, label: property.property.name };
  });
  const {
    data: balanceData,
    isLoading: balanceDataLoading,
    monthlyResults: balanceDataMonthlyResults,
  } = useBalanceSheetData();
  const {
    data: profitAndLossData,
    isLoading: profitAndLossDataLoading,
    monthlyResults: profitAndLossDataMonthlyResults,
  } = useProfitAndLossSheetData();

  const [tabValue, setTabValue] = useState<TabType>(TabType.BALANCE);

  const handleChangeYear = (newStartMonth: Dayjs, newEndMonth: Dayjs) => {
    setSelectedStartingYear(newStartMonth);
    setSelectedEndingYear(newEndMonth);
  };

  useEffect(() => {
    if (selectedEndingMonth.diff(selectedStartingMonth, "year") !== 1) {
      setSelectedStartingYear(dayjs().startOf("year"));
      setSelectedEndingYear(dayjs().endOf("year"));
    }
  }, []);

  return (
    <BaseTemplate headerTitle="損益計算書・収支表" onBack={() => history.goBack()} isDense>
      <Tabs
        value={tabValue}
        onChange={(_, newValue) => {
          setTabValue(newValue);
        }}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
        centered
      >
        <Tab label="収支表" />
        <Tab label="損益計算書" />
      </Tabs>
      <Box p={3}>
        <Box display="flex" justifyContent="center" mb={2}>
          <MonthRangeSelector
            label="表示期間"
            startMonth={selectedStartingMonth}
            endMonth={selectedEndingMonth}
            onChange={handleChangeYear}
          />
        </Box>
        <Box mb={2}>
          <MultiSelect
            label="表示物件"
            options={propertyOptions}
            selectedIds={selectedPropertyIds}
            onChange={setSelectedPropertyIds}
          />
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary">
            ※こちらの機能はユーザー様が入力された情報を基に作成されるものであり、当社がその正確性を保証するものではありません。
          </Typography>
        </Box>
      </Box>

      {selectedEndingMonth.diff(selectedStartingMonth, "year") !== 0 ||
      balanceDataLoading ||
      profitAndLossDataLoading ? (
        <Loading />
      ) : (
        <Box mx={3}>
          {tabValue === TabType.BALANCE ? (
            <SheetTable
              data={balanceData}
              monthlyResults={balanceDataMonthlyResults}
              selectedStartingMonth={selected_starting_month}
              isMobile={isMobile}
            />
          ) : (
            <>
              <SheetTable
                data={profitAndLossData}
                monthlyResults={profitAndLossDataMonthlyResults}
                selectedStartingMonth={selected_starting_month}
                isMobile={isMobile}
              />
              <Box mt={1}>
                <Typography variant="caption">
                  ※正確な計算につきましては税理士とご相談下さい。なお、上記損益計算書は青色申告特別控除額、土地等を取得するために要した負債の利子の額に関しては考慮しておりません。
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </BaseTemplate>
  );
};
