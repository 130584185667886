import { Box, makeStyles, Typography } from "@material-ui/core";
import { MailOutline, PhoneOutlined } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { FC, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router";

import { useApproveInvestmentProposal } from "../../../../hooks/useApproveInvestmentProposal";
import { useGetInvestmentProposalDetails } from "../../../../hooks/useGetInvestmentProposalDetails";
import { useUpdateInvestmentRead } from "../../../../hooks/useUpdateInvestmentRead";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { AgentProfile } from "../../../../scripts/components/renewal_v1/offer/agent_profile";
import { OfferApproveModal } from "../../../../scripts/components/renewal_v1/offer/approve_modal";
import { OfferDetailContent } from "../../../../scripts/components/renewal_v1/offer/content";
import { OfferDetailHeadline } from "../../../../scripts/components/renewal_v1/offer/headline";
import { StyledChip } from "../../../../scripts/components/renewal_v1/offer/strength_chip";
import { BaseTemplate } from "../../../../scripts/components/renewal_v1/template/BaseTemplate";
import { theme } from "../../../../scripts/components/renewal_v1/theme"; // theme providerが効いておらず古いthemeが適用されるため暫定対応
import { Loading } from "../../../loading";

const useStyles = makeStyles(() => ({
  section: {
    "&:not(:first-child)": {
      marginTop: theme.spacing(3),
    },
  },
  agentContactContent: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
  },
  agentContactLabel: {
    marginLeft: theme.spacing(1),
  },
}));

export const OfferInvestmentDetailPage: FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const classes = useStyles();

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  useUpdateInvestmentRead(Number(id));
  const { fetchInvestmentProposalById, proposalData, loading: fetching } = useGetInvestmentProposalDetails(Number(id));
  const {
    approveInvestmentProposal,
    loading: approving,
    errors: approveError,
  } = useApproveInvestmentProposal(Number(id));

  const loading = useMemo(() => fetching || approving, [fetching, approving]);

  // 特に強い分野の取得
  const strongFields = useMemo(() => {
    const tags = proposalData?.agent?.company?.speciality_tags.map((tag) => {
      return tag.name;
    });
    return tags;
  }, [proposalData]);

  // 話を聞いてみるボタンの押下時
  const handleClickModalApprove = async () => {
    window.scrollTo(0, 0);
    await approveInvestmentProposal();
    if (!approveError) {
      setIsApproveModalOpen(true);
      await fetchInvestmentProposalById();
    }
  };

  return (
    <BaseTemplate headerTitle="投資オファー詳細" onBack={() => history.push("/offer")}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box whiteSpace="pre-wrap">
            <Box className={classes.section}>
              {/* 日付 */}
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="caption" color="textSecondary">
                  {dayjs(proposalData?.proposal_date).format("YYYY年MM月DD日")}
                </Typography>
              </Box>

              {/* プロフィール画像 */}
              <AgentProfile
                profileImagePath={proposalData?.agent?.agent_profile_image?.image_url || ""}
                companyImagePath={proposalData?.agent?.company?.company_logo_image?.image_url || ""}
                companyName={proposalData?.agent?.company?.name || ""}
                companyHomePageUrl={proposalData?.agent?.company?.home_page_url}
                representativeName={proposalData?.agent?.family_name + proposalData?.agent?.first_name || ""}
              />

              {proposalData?.is_user_approved ? (
                <>
                  {/* 電話番号とメールアドレス */}
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Box className={classes.agentContactContent}>
                      <Box display="flex" alignItems="center">
                        <PhoneOutlined color="primary" fontSize="small" />
                        <Typography variant="h4" className={classes.agentContactLabel}>
                          {proposalData?.agent.phone_number}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <MailOutline color="primary" fontSize="small" />
                        <Typography variant="h4" className={classes.agentContactLabel}>
                          {proposalData?.agent.email}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {/* 提案メッセージ */}
                  <Box mt={2}>
                    <Typography variant="body1">{proposalData?.proposal_content}</Typography>
                  </Box>
                </>
              )}

              {/* 特に強い分野 */}
              {strongFields?.length > 0 && (
                <Box mt={2}>
                  <Box display="flex" flexWrap="wrap" mt={1}>
                    {strongFields.map((field, index) => (
                      <StyledChip label={field} key={"strength-" + index} />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>

            {/* プロフィール */}
            <Box className={classes.section}>
              <OfferDetailHeadline text="プロフィール" />
              <Box>
                <Typography variant="body1" color="textPrimary">
                  {proposalData?.agent?.profile}
                </Typography>
              </Box>
            </Box>

            {/* 会社情報 */}
            <Box className={classes.section}>
              <OfferDetailHeadline text="会社情報" />
              {/* 会社の実績 */}
              {proposalData?.agent?.company?.achievements && (
                <OfferDetailContent title="会社の実績" content={proposalData.agent.company.achievements} />
              )}

              {/* 販売物件/物件買取の強み */}
              {proposalData?.agent?.company?.strengths_of_properties_for_sale_and_purchase && (
                <OfferDetailContent
                  title="販売物件/物件買取の強み"
                  content={proposalData.agent.company.strengths_of_properties_for_sale_and_purchase}
                />
              )}

              {/* 提携金融機関 */}
              {proposalData?.agent?.company?.strengths_of_partner_financial_institutions && (
                <OfferDetailContent
                  title="提携金融機関"
                  content={proposalData.agent.company.strengths_of_partner_financial_institutions}
                />
              )}

              {/* 管理の強み */}
              {proposalData?.agent?.company?.strengths_of_properties_for_management && (
                <OfferDetailContent
                  title="販売後の物件管理"
                  content={proposalData.agent.company.strengths_of_properties_for_management}
                />
              )}

              {/* 自社ならではのアピールポイント */}
              {proposalData?.agent?.company?.appeal_point && (
                <OfferDetailContent
                  title="自社ならではのアピールポイント"
                  content={proposalData.agent.company.appeal_point}
                />
              )}
            </Box>

            {/* 話を聞いてみる */}
            <Box mb={2} sx={{ position: "fixed", bottom: 0, right: 0, left: 0 }}>
              <CustomButton
                customVariant="filled"
                onClick={handleClickModalApprove}
                disabled={proposalData?.is_user_approved}
              >
                話を聞いてみる
              </CustomButton>
            </Box>
          </Box>
        </>
      )}
      {isApproveModalOpen && <OfferApproveModal offerType="investment" onClose={() => setIsApproveModalOpen(false)} />}
    </BaseTemplate>
  );
};
