import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import React, { ReactNode } from "react";

interface Row {
  title: ReactNode;
  values: string[];
  type: "category" | "item" | "sum";
}

interface Props {
  data: Row[];
  monthlyResults: any[];
  selectedStartingMonth: dayjs.Dayjs;
  isMobile: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      position: "sticky",
      maxHeight: "calc(100vh - 96px)",
    },
    tableHead: {
      backgroundColor: theme.palette.common.white,
      borderBottom: `solid 1px ${theme.palette.primary.main}`,
      borderRight: `solid 1px ${theme.palette.grey[300]}`,
      zIndex: theme.zIndex.appBar + 3,
    },
    tableCell: {
      fontSize: "0.75rem",
      whiteSpace: "nowrap",
      borderRight: `solid 1px ${theme.palette.grey[300]}`,
      "&.category": {
        fontSize: ({ isMobile }: { isMobile: boolean }) => (isMobile ? "0.9em" : "1.17em"),
        fontWeight: "bold",
        borderBottom: "none",
        backgroundColor: theme.palette.common.white,
        padding: `${theme.spacing(2)} 0 ${theme.spacing(1.25)}`,
      },
      "&.item": {
        backgroundColor: theme.palette.grey[200],
        borderBottom: "none",
      },
      "&.sum": {
        backgroundColor: theme.palette.grey[200],
        fontWeight: "bold",
        borderTop: `solid 1px ${theme.palette.grey[300]}`,
        borderBottom: "none",
      },
    },
    stickyCell: {
      minWidth: "160px",
      maxWidth: "160px",
      whiteSpace: "normal",
      wordBreak: "keep-all",
      left: 0,
      position: "sticky",
      borderRight: "none",
      zIndex: theme.zIndex.appBar + 2,
      "&.category": {
        whiteSpace: "nowrap",
        overflowX: "visible",
      },
    },
    stickyHeaderFirstCell: {
      position: "sticky",
      borderRight: "none",
      zIndex: theme.zIndex.appBar + 4,
    },
  })
);

export const SheetTable: React.FC<Props> = ({ data, monthlyResults, selectedStartingMonth, isMobile }) => {
  const classes = useStyles({ isMobile });
  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.tableHead} ${classes.stickyHeaderFirstCell}`} />
            {monthlyResults.map((_, i) => (
              <TableCell key={i} align="right" className={classes.tableHead}>
                <Box fontWeight="bold">{selectedStartingMonth.isBefore(dayjs().startOf("year")) ? "実績" : "予測"}</Box>
                <Box fontWeight="bold">
                  {i === monthlyResults.length - 1 ? "年合計" : selectedStartingMonth.add(i, "month").format("YYYY/MM")}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <TableRow key={i}>
              <TableCell className={`${classes.stickyCell} ${classes.tableCell} ${row.type}`}>{row.title}</TableCell>
              {row.values.map((value, j) => (
                <TableCell key={j} align="right" className={`${classes.tableCell} ${row.type}`}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
