import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

import { CustomIconButton } from "../../../scripts/components/renewal_v1/custom_icon_button";
import { NavigationPath } from "../../../scripts/components/renewal_v1/enums";
import { BaseTemplate } from "../../../scripts/components/renewal_v1/template/BaseTemplate";
import { Toast } from "../../../scripts/components/renewal_v1/toast";
import { BaseTwoColumnTab } from "../../../scripts/components/renewal_v1/two_col_tab";
import { useDevice } from "../../../utilities/sp/use_device";
import { PropertyManagementListSnackbarState } from "../../../view_models/atoms";

import { AddNewPropertyModal } from "./management_tabs/add_new_property_modal";
import { ConsiderationPropertyTab } from "./management_tabs/consideration";
import { OwnPropertyTab } from "./management_tabs/own";

export const ManagementPage: React.FC = () => {
  const { isMobile } = useDevice();
  const history = useHistory();

  const [snackbarState, setSnackbarState] = useRecoilState(PropertyManagementListSnackbarState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickAddProperty = () => {
    window.scrollTo(0, 0);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setSnackbarState({ ...snackbarState, isOpen: false });
  };

  return (
    <BaseTemplate
      headerTitle="物件一覧"
      onBack={() => history.goBack()}
      navigationPath={NavigationPath.PROPERTY_MANAGEMENT}
      isDense
    >
      <Box margin="0 auto">
        <BaseTwoColumnTab
          firstTabLabel="保有中"
          firstTabChildren={<OwnPropertyTab />}
          secondTabLabel="検討中"
          secondTabChildren={<ConsiderationPropertyTab />}
        />
      </Box>

      {isMobile && (
        <CustomIconButton onClick={onClickAddProperty} float>
          <AddIcon />
        </CustomIconButton>
      )}
      <AddNewPropertyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <Toast
        open={snackbarState.isOpen}
        variant={snackbarState.status}
        message={snackbarState.message}
        onClose={handleClose}
      />
    </BaseTemplate>
  );
};
