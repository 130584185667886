import { Box, Chip, makeStyles } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import dayjs from "dayjs";
import React, { FC, useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilValueLoadable, useRecoilState, useRecoilValue } from "recoil";

import CozuchiBanner from "../../../../assets/promotion_images/2025-cozuchi-banner.jpg";
import { RegisteredStatusEnum, ScreenNameEnum } from "../../../api/generated/api";
import { useGetAssesmentProposals } from "../../../hooks/useGetAssesmentProposals";
import { useGetInvestmentProposals } from "../../../hooks/useGetInvestmentProposals";
import { useGetProposalMatchings } from "../../../hooks/useGetProposalMatchings";
import { usePostUserAccessScreenLogs } from "../../../hooks/usePostUserAccessScreenLogs";
import Notification from "../../../pages/renewal_v1/mypage/notification";
import { NavigationPath } from "../../../scripts/components/renewal_v1/enums";
import { BaseTemplate } from "../../../scripts/components/renewal_v1/template/BaseTemplate";
import {
  SelectedStartingMonth,
  SelectedEndingMonth,
  useSelectOwnedProperties,
  UserDataFirstRegisteredStatusState,
} from "../../../view_models/atoms";
import { LoanInformationOnToday, ReducedMonthlyBalanceSheets } from "../../../view_models/balancesheet_selectors";
import { OwnedProperties } from "../../../view_models/property_selectors";
import { hasUnread } from "../../../view_models/proposal_unread_counts";
import { Loading } from "../../loading";

import { CashFlowSection } from "./cash_flow_section";
import { MatchingAgentSection } from "./matching_agent_section";
import NotificationWaitOffer from "./notification_wait_offer";
import { TaxReturnSection } from "./tax_return_section";
import AppTour from "./tour";


const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    objectFit: "contain",
  },
  chipWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  chip: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0, 3),
    "&:hover, &:active": {
      backgroundColor: theme.palette.info.dark,
    },
    "&:focus": {
      backgroundColor: theme.palette.info.main,
    },
  },
  chipIcon: {
    color: theme.palette.common.white,
  },
}));

export const MyPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const loan_loadable = useRecoilValueLoadable(LoanInformationOnToday);
  const reduced_loadable = useRecoilValueLoadable(ReducedMonthlyBalanceSheets(null));

  const [selected_starting_month, setSelectedStartingYear] = useRecoilState(SelectedStartingMonth);
  const [selected_ending_month, setSelectedEndingYear] = useRecoilState(SelectedEndingMonth);
  const selectOwnedProperties = useSelectOwnedProperties();
  const properties_loadable = useRecoilValueLoadable(OwnedProperties);

  const userDataFirstRegisteredStatus = useRecoilValue(UserDataFirstRegisteredStatusState);
  const hasUnreadValue = useRecoilValue(hasUnread);

  const { proposalMatchings } = useGetProposalMatchings();
  usePostUserAccessScreenLogs(ScreenNameEnum.Top);

  const { assessmentProposals, loading: loadingAssessment } = useGetAssesmentProposals();
  const { investmentProposals, loading: loadingInvestment } = useGetInvestmentProposals();

  useEffect(() => {
    selectOwnedProperties();
    if (selected_ending_month.diff(selected_starting_month, "year") !== 45) {
      setSelectedStartingYear(dayjs().startOf("year"));
      setSelectedEndingYear(dayjs().add(45, "year").endOf("year"));
    }
  }, []);

  if (properties_loadable.state === "loading") {
    return <Loading />;
  }
  if (properties_loadable.state === "hasError") {
    throw properties_loadable.contents;
  }

  // // TODO WANTFIX: we can rewrite the following state checking as for-loop or iterator.
  // // I don't have any idea to pass type check of Typescript with the iterative approach.
  if (loan_loadable.state === "hasError") {
    throw loan_loadable.contents;
  }
  if (loan_loadable.state === "loading") {
    return <Loading />;
  }
  if (reduced_loadable.state === "hasError") {
    throw reduced_loadable.contents;
  } else if (reduced_loadable.state === "loading") {
    return <Loading />;
  }

  if (loadingAssessment || loadingInvestment) {
    return <Loading />;
  }

  const this_month_sheet = reduced_loadable.contents.get(dayjs().format("YYYY/MM"));

  return (
    <BaseTemplate headerTitle="ホーム" navigationPath={NavigationPath.HOME}>
      <AppTour />
      {userDataFirstRegisteredStatus !== RegisteredStatusEnum.Property && <Notification />}
      {![...(assessmentProposals || []), ...(investmentProposals || [])].length && <NotificationWaitOffer />}
      {hasUnreadValue && (
        <Box className={classes.chipWrapper}>
          <Chip
            className={classes.chip}
            icon={<MailIcon className={classes.chipIcon} />}
            label="未読の新規オファーがあります"
            onClick={() => history.push("/offer")}
          />
        </Box>
      )}
      <Box mt={1}>
        <CashFlowSection
          thisMonthResult={this_month_sheet.result}
          properties={properties_loadable.contents}
          loans={loan_loadable.contents}
        />
      </Box>
      <Box mt={1}>
        <a href="https://dribda.com/link.php?i=phy3zpexb2lo&m=mhy0ed110nik" target="_blank" rel="noopener noreferrer">
          <img className={classes.image} src={CozuchiBanner} alt="Cozuchi banner" />
        </a>
      </Box>
      <Box mt={1}>
        <MatchingAgentSection proposalMatchings={proposalMatchings} />
      </Box>
      <Box mt={1}>
        <TaxReturnSection />
      </Box>
    </BaseTemplate>
  );
};
