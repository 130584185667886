import { useState, useEffect } from "react";

import { InvestmentProposal } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

interface UseGetInvestmentProposals {
  investmentProposals: InvestmentProposal[];
  loading: boolean;
}

export const useGetInvestmentProposals = (): UseGetInvestmentProposals => {
  const [loading, setLoading] = useState(true);
  const [investmentProposals, setInvestmentProposals] = useState<InvestmentProposal[]>([]);

  useEffect(() => {
    const fetchInvestmentProposals = async () => {
      const fp = PropallyApiFactory();
      try {
        const data = await fp.v1InvestmentProposalsGet({ withCredentials: true });
        if (data.status === 200) {
          setInvestmentProposals(data.data);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchInvestmentProposals();
  }, []);

  return { investmentProposals, loading };
};
