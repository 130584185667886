import { Tabs, Tab, Typography, Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { deleteProperty } from "../../../api/delete_property";
import { PropallyApiFactory } from "../../../module/custom_api_factory";
import { DropDownMenu, DropDownMenuItem } from "../../../scripts/components/renewal_v1/dropdown_menu";
import { NavigationPath } from "../../../scripts/components/renewal_v1/enums";
import { ManagementDetailView } from "../../../scripts/components/renewal_v1/management/ManagementDetailView";
import { ChangePropertyImageModal } from "../../../scripts/components/renewal_v1/property/change_property_image_modal";
import { DeletePropertyModal } from "../../../scripts/components/renewal_v1/property/delete_property_modal";
import { BaseTemplate } from "../../../scripts/components/renewal_v1/template/BaseTemplate";
import theme from "../../../scripts/components/renewal_v1/theme";
import { Toast } from "../../../scripts/components/renewal_v1/toast";
import { AppEventTokenEnum, isFirstAccess, sendEventToAppMeasurementTool } from "../../../utilities/capacitor_logic";
import { fileToBase64EncodedImage } from "../../../utilities/file_to_base64_encoded_image";
import {
  PropertyManagementDetailSnackbarState,
  PropertyManagementListSnackbarState,
  SelectedPropertyId,
  SelectedPropertyIds,
  useRequestInitialLoading,
  UserState,
} from "../../../view_models/atoms";
import { MonthlyCashflowResultsOnToday } from "../../../view_models/balancesheet_selectors";
import { SelectedProperty } from "../../../view_models/property_selectors";
import { Loading } from "../../loading";

import { ManagementDetailCashflow } from "./management_detail_tabs/cashflow";
import { ManagementDetailPropertyInfo } from "./management_detail_tabs/property_info";
import { ManagementDetailSimulation } from "./management_detail_tabs/simulation";


const useStyles = makeStyles(() => {
  const PC_WIDTH = 800;
  const SP_WIDTH = 390;
  return {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: "3px",
    },
    tabs: {
      display: "flex",
      justifyContent: "center",
      borderBottom: `0.5px solid ${theme.palette.grey[300]}`,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(0),
      },
    },
    tab: {
      flexGrow: 1,
      textAlign: "center",
      borderBottom: "3px solid transparent",
      padding: 0,
      width: PC_WIDTH / 3,
      [theme.breakpoints.down("sm")]: {
        width: SP_WIDTH / 3,
      },
    },
    tabLabel: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
    tabSelected: {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    labelMenuIcon: {
      width: "24px",
      height: "24px",
      color: theme.palette.primary.main,
    },
    menuItem: {
      "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
    },
  };
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

export const ManagementDetailPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  // 削除モーダルの表示状態
  const [openDeletePropertyModal, setOpenDeletePropertyModal] = React.useState(false);
  const [openChangeImagePropertyModal, setOpenChangeImagePropertyModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const setSelectedPropertyId = useSetRecoilState(SelectedPropertyId);
  const setSelectedPropertyIds = useSetRecoilState(SelectedPropertyIds);
  const requestInitialLoading = useRequestInitialLoading();
  // 物件一覧での表示させるスナックバーのための関数
  const setManagementListSnackBarState = useSetRecoilState(PropertyManagementListSnackbarState);
  // このページでのスナックバー表示用の状態
  const [snackbarState, setSnackbarState] = useRecoilState(PropertyManagementDetailSnackbarState);

  const properties_loadable = useRecoilValueLoadable(SelectedProperty);
  const cashflow_result_loadable = useRecoilValueLoadable(MonthlyCashflowResultsOnToday);

  const user = useRecoilValue(UserState);

  // 右上の設定ボタン用の処理
  const handleClickImageChange = React.useCallback(() => {
    setOpenChangeImagePropertyModal(true);
  }, [setOpenChangeImagePropertyModal]);

  const handleClickDeleteProperty = React.useCallback(() => {
    setOpenDeletePropertyModal(true);
  }, [setOpenDeletePropertyModal]);

  if (properties_loadable.state === "loading") {
    return <Loading />;
  }
  if (properties_loadable.state === "hasError") {
    throw properties_loadable.contents;
  }
  const property = properties_loadable.contents;
  if (cashflow_result_loadable.state === "loading") {
    return <Loading />;
  }
  if (cashflow_result_loadable.state === "hasError") {
    throw cashflow_result_loadable.contents;
  }
  const cashflow_result = cashflow_result_loadable.contents;

  // Check if property is undefined
  if (!property) {
    return <Loading />;
  }

  // 物件削除モーダルで削除ボタンを押したときの処理
  const handleDeleteThisProperty = async () => {
    const result = await deleteProperty(property);
    if (result.success) {
      setSelectedPropertyIds([]);
      setSelectedPropertyId(null);

      requestInitialLoading();
      // 物件一覧ページでのスナックバー表示用
      setManagementListSnackBarState({ isOpen: true, message: "物件を削除しました", status: "success" });
      history.push("/management");
    } else {
      setManagementListSnackBarState({ isOpen: true, message: "物件を削除できませんでした", status: "error" });
      history.push("/management");
    }
  };

  // 物件の画像の登録
  const handleUpdateImageThisProperty = async (imageFile: File) => {
    const base64_encoded_image = await fileToBase64EncodedImage(imageFile);
    const fp = PropallyApiFactory();
    if (!base64_encoded_image) {
      setOpenChangeImagePropertyModal(false);
      return;
    }
    const result = await fp.v1PropertiesPropertyIdImagesPost(property.id, base64_encoded_image, {
      withCredentials: true,
    });
    if (result.status === 201) {
      // 画像の登録に成功したら、物件情報を再取得する
      requestInitialLoading();
      setOpenChangeImagePropertyModal(false);
      setManagementListSnackBarState({ isOpen: true, message: "画像を変更しました", status: "success" });
      history.push("/management");
    } else {
      setOpenChangeImagePropertyModal(false);
      setManagementListSnackBarState({ isOpen: true, message: "画像を変更できませんでした", status: "error" });
      history.push("/management");
    }
  };

  const handleChange = (event, newValue) => {
    console.log("newValue: ", newValue);
    if (newValue === 0) {
      sendEventToAppMeasurementTool(
        isFirstAccess(user?.created_at)
          ? AppEventTokenEnum.PropertyTabBalanceOfPaymentsFirstAccess
          : AppEventTokenEnum.PropertyTabBalanceOfPaymentsSecondOnwardsAccess
      );
    } else if (newValue === 1) {
      sendEventToAppMeasurementTool(
        isFirstAccess(user?.created_at)
          ? AppEventTokenEnum.PropertyTabSimulationFirstAccess
          : AppEventTokenEnum.PropertyTabSimulationSecondOnwardsAccess
      );
    } else if (newValue === 2) {
      sendEventToAppMeasurementTool(
        isFirstAccess(user?.created_at)
          ? AppEventTokenEnum.PropertyTabInformationFirstAccess
          : AppEventTokenEnum.PropertyTabInformationSecondOnwardsAccess
      );
    }
    setValue(newValue);
  };

  // スナックバーの表示状態
  const handleClose = () => {
    setSnackbarState({ ...snackbarState, isOpen: false });
  };

  // ドロップダウンメニューの項目
  const menuItems: DropDownMenuItem[] = [
    { label: "画像を変更する", onClick: handleClickImageChange, color: "textPrimary" },
    { label: "物件を削除する", onClick: handleClickDeleteProperty, color: "error" },
  ];

  const onBack = () => {
    history.push("/management");
  };
  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  return (
    <BaseTemplate
      headerTitle="物件一覧"
      onBack={onBack}
      navigationPath={NavigationPath.PROPERTY_MANAGEMENT}
      HeaderActionComponent={
        <>
          <IconButton className={classes.labelMenuIcon} onClick={handleSettingsClick}>
            <MoreVertIcon fontSize="large" />
          </IconButton>
          <DropDownMenu anchorEl={anchorEl} onClose={handleSettingsClose} itemOptions={menuItems} />
        </>
      }
      isDense
    >
      <ManagementDetailView propertyType="保有中" propertyHolder={property} monthlyBalanceSheet={cashflow_result}>
        <div className={classes.root}>
          <Box className={classes.tabs}>
            <Tabs
              value={value}
              onChange={handleChange}
              classes={{ indicator: classes.indicator }}
              aria-label="simple tabs example"
              textColor="primary"
            >
              <Tab
                label="運用状況"
                classes={{
                  textColorPrimary: classes.tabLabel,
                  selected: classes.tabSelected,
                }}
                className={classes.tab}
              />
              <Tab
                label="シミュレーション"
                classes={{ textColorPrimary: classes.tabLabel, selected: classes.tabSelected }}
                className={classes.tab}
              />
              <Tab
                label="物件情報"
                classes={{ textColorPrimary: classes.tabLabel, selected: classes.tabSelected }}
                className={classes.tab}
              />
            </Tabs>
          </Box>
          {/* 運用状況タブ */}
          <TabPanel value={value} index={0}>
            <ManagementDetailCashflow />
          </TabPanel>

          {/* シミュレーションタブ */}
          <TabPanel value={value} index={1}>
            <ManagementDetailSimulation />
          </TabPanel>

          {/* 物件情報タブ */}
          <TabPanel value={value} index={2}>
            <ManagementDetailPropertyInfo />
          </TabPanel>

          {openDeletePropertyModal && (
            <DeletePropertyModal
              onClose={() => setOpenDeletePropertyModal(false)}
              onClickCancel={() => setOpenDeletePropertyModal(false)}
              onClickDelete={handleDeleteThisProperty}
            />
          )}
          {openChangeImagePropertyModal && (
            <ChangePropertyImageModal
              onClose={() => setOpenChangeImagePropertyModal(false)}
              onClickCancel={() => setOpenChangeImagePropertyModal(false)}
              onClickChangeImage={handleUpdateImageThisProperty}
            />
          )}
        </div>
        <Toast
          open={snackbarState.isOpen}
          variant={snackbarState.status}
          message={snackbarState.message}
          onClose={handleClose}
        />
      </ManagementDetailView>
    </BaseTemplate>
  );
};
