import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import { usePatchUser } from "../../../../hooks/usePatchUser";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../../../scripts/components/renewal_v1/form";
import {
  EMAIL_FORMAT_MESSAGE,
  EMAIL_MAX_LENGTH_MESSAGE,
  EMAIL_REGEX,
  EMAIL_REQUIRED_MESSAGE,
  MAX_EMAIL_LENGTH,
} from "../../../../utilities/renewal_v1/const";
import { SettingItemsProps } from "../SettingsPage";

export const UpdateEmail: React.FC<SettingItemsProps> = ({ user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, errors, control } = useForm();

  const { patchUser, response, loading, error } = usePatchUser();

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [error]);

  return (
    <>
      {response ? (
        <Box mb={4} display="flex" flexDirection="column" textAlign="center" style={{ gap: "12px" }}>
          <Typography variant="h3">確認メール送信完了</Typography>
          <Typography>
            新しいメールアドレスに、確認メールを送信しました。
            <br />
            メール内のリンクをクリックして、メールアドレスを更新してください。
            <Box component="span" fontWeight="bold">
              リンクをクリックするまでは、メールアドレスは古いままで更新されません。
            </Box>
          </Typography>
          <Typography variant="subtitle1">{response.email}</Typography>
          <Typography>
            しばらくお待ちいただいてもメールが届かない場合は、迷惑メールボックスもご確認ください。
            <br />
            それでもメールが見つからない場合は、恐れ入りますがもう一度メールアドレスの変更をお試しください。
          </Typography>
        </Box>
      ) : (
        <form autoComplete="off" onSubmit={handleSubmit(patchUser)}>
          <Box display="flex" flexDirection="column" style={{ gap: "24px" }}>
            <Box>
              <Typography variant="h5">現在のメールアドレス</Typography>
              <Typography>{user.email}</Typography>
            </Box>
            <Controller
              name="email"
              control={control}
              defaultValue={""}
              rules={{
                required: EMAIL_REQUIRED_MESSAGE,
                maxLength: {
                  value: MAX_EMAIL_LENGTH,
                  message: EMAIL_MAX_LENGTH_MESSAGE,
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: EMAIL_FORMAT_MESSAGE,
                },
              }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="新しいメールアドレス"
                  type="text"
                  required
                  autoComplete="email"
                  error={!!errors.email}
                  errorText={Boolean(errors.email) && errors.email.message}
                  placeHolder="sample@example.com"
                />
              )}
            />

            <Box display="flex" justifyContent="center">
              <CustomButton type="submit" customVariant="filled" disabled={loading}>
                更新
              </CustomButton>
            </Box>
          </Box>
        </form>
      )}
    </>
  );
};
