import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { IdName } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

export const useGetMasterResidenceTypes = (): {
  masterResidenceTypes: IdName[];
  loading: boolean;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [masterResidenceTypes, setMasterResidenceTypes] = useState([]);

  const fp = PropallyApiFactory();

  const getMasterResidenceTypes = async () => {
    try {
      setLoading(true);
      const response = await fp.v1MasterDataResidenceTypesGet({
        withCredentials: true,
      });

      setMasterResidenceTypes(response.data);
    } catch (e) {
      if (e.response?.status === 401) {
        enqueueSnackbar("画面をリロードし、再度ログインしてください");
      } else {
        enqueueSnackbar("ネットワークエラーです。しばらくたってからお試しください");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMasterResidenceTypes();
  }, []);

  return { masterResidenceTypes, loading };
};

export const useGetMasterTags = (): {
  masterTags: IdName[];
  loading: boolean;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [masterTags, setMasterTags] = useState([]);

  const fp = PropallyApiFactory();

  const getMasterTags = async () => {
    try {
      setLoading(true);
      const response = await fp.v1MasterDataTagsGet({
        withCredentials: true,
      });

      setMasterTags(response.data);
    } catch (e) {
      if (e.response?.status === 401) {
        enqueueSnackbar("画面をリロードし、再度ログインしてください");
      } else {
        enqueueSnackbar("ネットワークエラーです。しばらくたってからお試しください");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMasterTags();
  }, []);

  return { masterTags, loading };
};

export const useGetMasterPurposeOfUses = (): {
  masterPurposeOfUses: IdName[];
  loading: boolean;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [masterPurposeOfUses, setMasterPurposeOfUses] = useState([]);

  const fp = PropallyApiFactory();

  const getPurposeOfUses = async () => {
    try {
      setLoading(true);
      const response = await fp.v1MasterDataPurposeOfUsesGet({
        withCredentials: true,
      });

      setMasterPurposeOfUses(response.data);
    } catch (e) {
      if (e.response?.status === 401) {
        enqueueSnackbar("画面をリロードし、再度ログインしてください");
      } else {
        enqueueSnackbar("ネットワークエラーです。しばらくたってからお試しください");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPurposeOfUses();
  }, []);

  return { masterPurposeOfUses, loading };
};

export const useGetMasterInflowRoutes = (): {
  masterInflowRoutes: IdName[];
  loading: boolean;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [masterInflowRoutes, setMasterInflowRoutes] = useState([]);

  const fp = PropallyApiFactory();

  const getInflowRoutes = async () => {
    try {
      setLoading(true);
      const response = await fp.v1MasterDataInflowRoutesGet({
        withCredentials: true,
      });

      setMasterInflowRoutes(response.data);
    } catch (e) {
      if (e.response?.status === 401) {
        enqueueSnackbar("画面をリロードし、再度ログインしてください");
      } else {
        enqueueSnackbar("ネットワークエラーです。しばらくたってからお試しください");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInflowRoutes();
  }, []);

  return { masterInflowRoutes, loading };
};
