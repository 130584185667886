import { Box, ThemeProvider, makeStyles, Theme } from "@material-ui/core";
import React, { FC, ReactNode } from "react";

import { useDevice } from "../../../../utilities/sp/use_device";
import { NavigationPath } from "../enums";
import Footer from "../footer";
import Header from "../header";
import NavigationBar from "../navigation";
import { ManagementAppBar } from "../property/app_bar";
import theme from "../theme";

const useStyles = makeStyles<Theme, { isDense?: boolean }>((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "env(safe-area-inset-top)",
    },
  },

  main: (props) => ({
    padding: props.isDense ? theme.spacing(0) : theme.spacing(2),
    paddingBottom: "88px", // MuiBottomNavigationの高さ(72px)+ 16px 余白を追加する
    flexGrow: 1,
  }),
}));

interface BaseTemplateProps {
  headerTitle?: string;
  HeaderActionComponent?: ReactNode;
  onBack?: () => void;
  isDense?: boolean;
  navigationPath?: NavigationPath;
  children: ReactNode;
}

export const BaseTemplate: FC<BaseTemplateProps> = ({
  navigationPath,
  headerTitle,
  HeaderActionComponent,
  onBack,
  isDense = false,
  children,
}) => {
  const { isMobile } = useDevice();

  const classes = useStyles({ isDense });

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        {isMobile ? (
          <Header title={headerTitle} onBack={onBack} ActionComponent={HeaderActionComponent} />
        ) : (
          <ManagementAppBar />
        )}

        <Box component="main" className={classes.main}>
          {children}
        </Box>
        {isMobile ? navigationPath !== undefined && <NavigationBar path={navigationPath} /> : <Footer />}
      </Box>
    </ThemeProvider>
  );
};
