import { Box, FormGroup, Typography } from "@material-ui/core";
import { makeStyles, Grid, CircularProgress } from "@material-ui/core";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useState, useEffect } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";

import { JobCategory } from "../../../../api/generated/api";
import { usePatchUser } from "../../../../hooks/usePatchUser";
import { useSendPhoneNumberAuthCode } from "../../../../hooks/useSendPhoneNumberAuthCode";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { CustomCheckbox } from "../../../../scripts/components/renewal_v1/checkbox";
import { CustomForm } from "../../../../scripts/components/renewal_v1/form";
import CustomFormLabel from "../../../../scripts/components/renewal_v1/form_label";
import { CustomInput } from "../../../../scripts/components/renewal_v1/input";
import { CustomSelector } from "../../../../scripts/components/renewal_v1/selecter";
import { parseFormattedNumber } from "../../../../utilities/renewal_v1/format";
import {
  JOB_CATEGORY_OPTIONS,
  LOAN_RANGE_OPTIONS,
  MONTH_OPTIONS,
  OCCUPATION_OPTIONS,
  PERIOD_RANGE_OPTIONS,
  PREFECTURE_OPTIONS,
  YEAR_OPTIONS,
} from "../../../../utilities/renewal_v1/options";
import { SettingItemsProps } from "../SettingsPage";

// 入力率計算から除外させたい項目のkeyを格納する
const KEYS_EXCLUDED_FROM_INPUT_RATE: string[] = [
  "home_loan_amount",
  "monthly_rent_payment_amount",
  "other_request_text",
];

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    // color: theme.palette.error.main,
    color: "#DE351F",
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

export const BasicInfo: React.FC<SettingItemsProps> = ({
  user,
  setUser,
  masterResidenceTypes = [],
  masterTags = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { patchUser, loading, error } = usePatchUser();
  const { sendPhoneNumberAuthCode } = useSendPhoneNumberAuthCode();

  const { handleSubmit, errors, control, getValues } = useForm({});

  const { current_residence_type_id, job_category, phone_number, holding_property_count } = useWatch({
    name: ["current_residence_type_id", "job_category", "phone_number", "holding_property_count"],
    control: control,
  });

  // 入力率の計算処理
  const [inputCompletedRate, setInputCompletedRate] = useState<number>(0);

  const updateSettingRate = async () => {
    const form = getValues();
    const targetForm = Object.keys(form).filter((key) => !KEYS_EXCLUDED_FROM_INPUT_RATE.includes(key));
    const count = targetForm.filter((key) => String(form[key])?.length && form[key] !== "未選択").length;
    setInputCompletedRate(Math.floor((count / targetForm.length) * 100));
  };

  const residenceTypeOptions = masterResidenceTypes.map((residenceType) => ({
    value: residenceType.id,
    label: residenceType.name,
  }));

  const handleCheckInterestTag = (checkedId: number) => {
    const { interest_tag_ids: selected_ids } = getValues();
    return selected_ids?.includes(checkedId)
      ? selected_ids?.filter((id: number) => id !== checkedId)
      : [...(selected_ids ?? []), checkedId];
  };

  const onSubmit = async (data) => {
    const sendValues = {
      ...data,
      email: user.email,
      birthday: `${data.birth_year}-${data.birth_month}-01`,
      home_loan_amount: parseFormattedNumber(data.home_loan_amount),
      real_estate_loan_amount: parseFormattedNumber(data.real_estate_loan_amount),
      monthly_rent_payment_amount: parseFormattedNumber(data.monthly_rent_payment_amount),
      annual_income: parseFormattedNumber(data.annual_income),
    };

    await patchUser(sendValues);

    if (!error) {
      setUser({ ...user, ...sendValues });
      enqueueSnackbar("更新に成功しました");
    }
  };

  useEffect(() => {
    // チェックボックスの初期値設定
    control.setValue("interest_tag_ids", user.interest_tag_ids);

    // 入力率の計算
    updateSettingRate();
  }, [user, control]);

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <CustomFormLabel label="入力率" />
            </Grid>
            <Grid item xs={4}>
              <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" value={inputCompletedRate} size="60" />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    inputCompletedRate
                  )}%`}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <CustomFormLabel label="ID" />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">{user.identifier}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="family_name"
              control={control}
              defaultValue={user.family_name || ""}
              rules={{ required: "姓を入力してください。" }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="姓"
                  type="text"
                  required
                  error={!!errors.family_name}
                  errorText={errors.family_name?.message}
                  placeHolder="山田"
                  onBlur={updateSettingRate}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="first_name"
              control={control}
              defaultValue={user.first_name || ""}
              rules={{ required: "名を入力してください。" }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="名"
                  type="text"
                  required
                  error={!!errors.first_name}
                  errorText={errors.first_name?.message}
                  placeHolder="太郎"
                  onBlur={updateSettingRate}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="family_name_kana"
              control={control}
              defaultValue={user.family_name_kana || ""}
              rules={{
                required: "姓（カナ）を入力してください。",
                pattern: {
                  value: /^[ァ-ンヴー]*$/,
                  message: "※全角カタカナで入力してください。",
                },
              }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="姓（カナ）"
                  type="text"
                  required
                  error={!!errors.family_name_kana}
                  errorText={errors.family_name_kana?.message}
                  placeHolder="ヤマダ"
                  onBlur={updateSettingRate}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="first_name_kana"
              control={control}
              defaultValue={user.first_name_kana || ""}
              rules={{
                required: "名（カナ）を入力してください。",
                pattern: {
                  value: /^[ァ-ンヴー]*$/,
                  message: "※全角カタカナで入力してください。",
                },
              }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="名（カナ）"
                  type="text"
                  required
                  error={!!errors.first_name_kana}
                  errorText={errors.first_name_kana?.message}
                  placeHolder="タロウ"
                  onBlur={updateSettingRate}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <Controller
              name="birth_year"
              control={control}
              defaultValue={dayjs(user.birthday).get("year") || ""}
              rules={{
                required: "年を入力してください。",
                validate: (value) => new Date().getFullYear() >= value || "過去の年を入力してください。",
              }}
              render={({ ...field }) => (
                <CustomSelector
                  {...field}
                  label="生年月"
                  required
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.birth_year}
                  errorText={errors.birth_year?.message}
                  options={YEAR_OPTIONS}
                  placeHolder="1996"
                  isPlaceHolderGray
                  suffix="年"
                  onBlur={updateSettingRate}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="birth_month"
              control={control}
              defaultValue={dayjs(user.birthday).get("month") + 1 || ""}
              rules={{
                required: "月を入力してください。",
              }}
              render={({ ...field }) => (
                <CustomSelector
                  {...field}
                  required
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.birth_month}
                  errorText={errors.birth_month?.message}
                  options={MONTH_OPTIONS}
                  placeHolder="5"
                  isPlaceHolderGray
                  suffix="月"
                  onBlur={updateSettingRate}
                />
              )}
            />
          </Grid>
        </Grid>
        <Controller
          name="phone_number"
          control={control}
          defaultValue={user.phone_number || ""}
          rules={{
            required: "電話番号を入力してください。",
            pattern: {
              value: /^[0-9]{10,11}$/,
              message: "※正しい電話番号をご入力ください。",
            },
          }}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="電話番号"
              type="text"
              required
              error={!!errors.phone_number}
              errorText={errors.phone_number?.message}
              placeHolder="08001234567"
              onBlur={updateSettingRate}
            />
          )}
        />
        {user.phone_number !== phone_number && (
          <>
            <Box alignItems="center" justifyContent="center" display="flex" mb={3}>
              <CustomButton
                customVariant="filled"
                disabled={loading}
                onClick={() => sendPhoneNumberAuthCode(phone_number)}
              >
                電話番号認証コード取得
              </CustomButton>
            </Box>

            <Controller
              name="phone_number_auth_code"
              defaultValue={""}
              control={control}
              rules={{
                required: "電話番号の新規登録または更新時に必須入力です",
              }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="電話番号認証コード"
                  type="number"
                  required
                  error={!!errors.phone_number_auth_code}
                  errorText={errors.phone_number_auth_code?.message}
                  helperText={errors ? "" : "電話番号の新規登録または更新時に必須入力です"}
                />
              )}
            />
          </>
        )}
        <Controller
          name="address_prefecture"
          control={control}
          defaultValue={user.address_prefecture || ""}
          rules={{ required: "お住まいの地域（都道府県）を選択してください。" }}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              required
              label="お住まいの地域（都道府県）"
              error={!!errors.address_prefecture}
              errorText={errors.address_prefecture?.message}
              onChange={field.onChange}
              options={PREFECTURE_OPTIONS}
              placeHolder="選択してください"
              onBlur={updateSettingRate}
            />
          )}
        />
        <Controller
          name="job_category"
          control={control}
          rules={{ required: "ご職業を選択してください。" }}
          defaultValue={user.job_category || ""}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              required
              label="ご職業"
              error={!!errors.job_category}
              errorText={errors.job_category?.message}
              onChange={field.onChange}
              options={OCCUPATION_OPTIONS}
              placeHolder="選択してください"
              onBlur={updateSettingRate}
            />
          )}
        />
        {/* 業種条件付きで表示 */}
        {[JobCategory.会社員上場, JobCategory.会社員非上場, JobCategory.会社員外資系企業].includes(job_category) && (
          <Controller
            name="job_industry"
            defaultValue={user.job_industry || ""}
            control={control}
            rules={{ required: "業種を選択してください。" }}
            render={({ ...field }) => (
              <CustomSelector
                {...field}
                required
                label="業種"
                error={!!errors.job_industry}
                errorText={errors.job_industry?.message}
                onChange={field.onChange}
                options={JOB_CATEGORY_OPTIONS}
                placeHolder="選択してください"
                onBlur={updateSettingRate}
              />
            )}
          />
        )}
        <Controller
          name="job_continuous_period"
          control={control}
          defaultValue={user.job_continuous_period || ""}
          rules={{ required: "勤続年数を選択してください。" }}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              required
              label="勤続年数"
              error={!!errors.job_continuous_period}
              errorText={errors.job_continuous_period?.message}
              onChange={field.onChange}
              options={PERIOD_RANGE_OPTIONS}
              placeHolder="選択してください"
              onBlur={updateSettingRate}
            />
          )}
        />
        <Controller
          name="annual_income"
          control={control}
          defaultValue={user.annual_income ?? ""}
          rules={{
            required: "年収を入力してください。",
          }}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              required
              label="年収"
              error={!!errors.annual_income}
              errorText={errors.annual_income?.message}
              onChange={field.onChange}
              placeHolder="入力してください"
              unitSuffix="万円"
              formatNumeric
              onBlur={updateSettingRate}
            />
          )}
        />
        <Controller
          name="financial_asset"
          control={control}
          defaultValue={user.financial_asset || ""}
          rules={{ required: "金融資産（預金・株式等）を選択してください。" }}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              required
              label="金融資産（預金・株式等）"
              error={!!errors.financial_asset}
              errorText={errors.financial_asset?.message}
              onChange={field.onChange}
              options={LOAN_RANGE_OPTIONS}
              placeHolder="選択してください"
              onBlur={updateSettingRate}
            />
          )}
        />
        <Controller
          name="holding_property_count"
          defaultValue={user.holding_property_count ?? ""}
          control={control}
          rules={{
            required: "投資用不動産保有数を選択してください。",
            pattern: {
              value: /^[0-9]+$/,
              message: "半角数字で入力してください。",
            },
          }}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              required
              label="投資用不動産保有数"
              helperText="※住宅ローンで組んでいる物件は含みません"
              error={!!errors.holding_property_count}
              errorText={errors.holding_property_count?.message}
              onChange={field.onChange}
              placeHolder="入力してください"
              unitSuffix="件"
              onBlur={updateSettingRate}
            />
          )}
        />
        <Controller
          name="real_estate_loan_amount"
          control={control}
          rules={{
            required:
              holding_property_count && Number(holding_property_count) > 0
                ? "投資用不動産ローン合計を選択してください。"
                : false,
          }}
          defaultValue={user.real_estate_loan_amount ?? ""}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              required
              label="投資用不動産ローン合計"
              helperText="※住宅ローン残高は含みません。"
              error={!!errors.real_estate_loan_amount}
              errorText={errors.real_estate_loan_amount?.message}
              onChange={field.onChange}
              placeHolder="入力してください"
              unitSuffix="万円"
              formatNumeric
              onBlur={updateSettingRate}
            />
          )}
        />
        <Controller
          name="current_residence_type_id"
          control={control}
          defaultValue={user.current_residence_type_id || ""}
          rules={{ required: "お住まいの家を選択してください。" }}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              required
              label="お住まいの家"
              error={!!errors.current_residence_type_id}
              errorText={errors.current_residence_type_id?.message}
              onChange={field.onChange}
              options={residenceTypeOptions}
              placeHolder="選択してください"
              onBlur={updateSettingRate}
            />
          )}
        />
        {current_residence_type_id === 1 && (
          <Controller
            name="home_loan_amount"
            control={control}
            defaultValue={user.home_loan_amount ?? ""}
            rules={{
              required: "住宅ローン残高を入力してください。",
            }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                required
                label="住宅ローン残高"
                error={!!errors.home_loan_amount}
                errorText={errors.home_loan_amount?.message}
                onChange={field.onChange}
                placeHolder="入力してください"
                unitSuffix="万円"
                formatNumeric
                onBlur={updateSettingRate}
              />
            )}
          />
        )}
        {current_residence_type_id === 2 && (
          <Controller
            name="monthly_rent_payment_amount"
            control={control}
            defaultValue={user.monthly_rent_payment_amount ?? ""}
            rules={{
              required: "家賃を入力してください。",
            }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                required
                label="家賃"
                error={!!errors.monthly_rent_payment_amount}
                errorText={errors.monthly_rent_payment_amount?.message}
                onChange={field.onChange}
                placeHolder="入力してください"
                unitSuffix="円"
                formatNumeric
                onBlur={updateSettingRate}
              />
            )}
          />
        )}
        <Box my={1}>
          <CustomFormLabel label="興味のある項目" required={true} />
          <Controller
            name="interest_tag_ids"
            defaultValue={[]}
            control={control}
            rules={{ validate: (v) => v?.length > 0 || "最低1つの項目を選択してください。" }}
            render={({ ...field }) => (
              <FormGroup>
                <Grid container wrap="wrap">
                  {masterTags.map((masterTag) => (
                    <Grid item xs={4} key={masterTag.id}>
                      <CustomCheckbox
                        {...field}
                        key={masterTag.id}
                        label={masterTag.name}
                        error={!!errors.interest_tag_ids}
                        onChange={() => field.onChange(handleCheckInterestTag(masterTag.id))}
                        checked={field.value?.includes(masterTag.id)}
                        onBlur={updateSettingRate}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            )}
          />
          {errors && (
            <Typography variant="caption" className={classes.errorMessage}>
              {errors.interest_tag_ids?.message}
            </Typography>
          )}
        </Box>
        <Box my={1}>
          <Controller
            name="other_request_text"
            control={control}
            defaultValue={user.other_request_text}
            render={({ ...field }) => (
              <CustomInput
                {...field}
                label="その他のご要望"
                error={errors.other_request_text?.message}
                onChange={field.onChange}
                helperText="※記載いただくと、より正確なオファーがきます"
                placeholder="例：まずは不動産投資の基礎を知りたいです"
                maxLength={2000}
                onBlur={updateSettingRate}
              />
            )}
          />
        </Box>
        <Box justifyContent="center" display="flex">
          <CustomButton type="submit" customVariant="filled" disabled={loading}>
            更新
          </CustomButton>
        </Box>
      </form>
    </>
  );
};
