import Box from "@material-ui/core/Box";
import * as React from "react";

import { useLogout } from "../../../../hooks/useLoagout";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { SettingItemsProps } from "../SettingsPage";

export const LogoutTab: React.FC<SettingItemsProps> = () => {
  const { onClickLogout } = useLogout();

  return (
    <Box justifyContent="center" display="flex">
      <CustomButton customVariant="filled" onClick={() => onClickLogout()}>
        ログアウト
      </CustomButton>
    </Box>
  );
};
