import { makeStyles, Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";

import { LatestHistoryContent } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history";
import { BaseTemplate } from "../../../../../../scripts/components/renewal_v1/template/BaseTemplate";
import { PropertyHolder } from "../../../../../../view_models/atoms";

import { EditPropertySectionLeaseContractHistory } from "./history";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "16px auto 0px auto",
  },
}));

interface EditPropertySectionLeaseContractProps {
  propertyHolder: PropertyHolder;
  onClickPageBack: () => void;
}

export const EditPropertySectionLeaseContract: React.FC<EditPropertySectionLeaseContractProps> = ({
  propertyHolder,
  onClickPageBack,
}) => {
  const classes = useStyles();
  // 過去履歴の表示・非表示
  const [isShowHistory, setIsShowHistory] = React.useState(false);

  const latestLeaseContract =
    propertyHolder.lease_contracts.length > 0
      ? propertyHolder.lease_contracts.reduce((latest, contract) => {
          const latestDate = new Date(latest.starts_at);
          const contractDate = new Date(contract.starts_at);
          return contractDate > latestDate ? contract : latest;
        })
      : null;

  const startToEndMonth = `${dayjs(latestLeaseContract?.starts_at).format("YYYY/MM")} ~ ${dayjs(
    latestLeaseContract?.ends_at
  ).format("YYYY/MM")}`;

  const monthlyFee = latestLeaseContract?.monthly_fee
    ? `${latestLeaseContract?.monthly_fee.toLocaleString()}円`
    : "-円";
  const monthlyManagementFee = latestLeaseContract?.monthly_management_fee
    ? `${latestLeaseContract?.monthly_management_fee.toLocaleString()}円`
    : "-円";

  // 賃貸借契約開始時における収入 / 賃貸借契約開始時における支出
  const typeOfIncomeBeginningContractMap = {
    KeyMoney: "礼金",
    SecurityDeposit: "更新料",
  };
  const typeOfIncomeBeginningContract = latestLeaseContract?.type_of_the_income_of_the_beginning_of_contract
    ? typeOfIncomeBeginningContractMap[latestLeaseContract?.type_of_the_income_of_the_beginning_of_contract]
    : "礼金";
  let income = "-";
  let typeOfOutcomeBeginningContract = "";
  let outcome = "-";
  if (latestLeaseContract?.type_of_the_income_of_the_beginning_of_contract === "KeyMoney") {
    income = `${latestLeaseContract?.key_money.toLocaleString()}ヶ月分`;
    typeOfOutcomeBeginningContract = "新規賃貸契約手数料";
    outcome = latestLeaseContract?.outsourcing_fee_rate_of_new_lease_contract
      ? `${latestLeaseContract?.outsourcing_fee_rate_of_new_lease_contract.toLocaleString()}ヶ月分`
      : "-";
  } else if (latestLeaseContract?.type_of_the_income_of_the_beginning_of_contract === "SecurityDeposit") {
    income = `${latestLeaseContract?.security_deposit.toLocaleString()}ヶ月分`;
    typeOfOutcomeBeginningContract = "賃貸契約更新時手数料";
    outcome = latestLeaseContract?.outsourcing_fee_rate_of_updating_lease_contract
      ? `${latestLeaseContract?.outsourcing_fee_rate_of_updating_lease_contract.toLocaleString()}ヶ月分`
      : "-";
  }

  // 過去の履歴
  const onClickPageBackFromHistory = () => {
    setIsShowHistory(false);
  };
  const handleClickShowMoreHistory = () => {
    setIsShowHistory(true);
  };
  if (isShowHistory) {
    return <EditPropertySectionLeaseContractHistory onClickPageBack={onClickPageBackFromHistory} />;
  }
  return (
    <BaseTemplate headerTitle="賃貸契約情報" onBack={onClickPageBack}>
      <Box>
        <Box className={classes.content}>
          {/* 現在入居者の賃貸契約情報 */}
          <LatestHistoryContent
            title="現在入居者の賃貸契約情報"
            contents={[
              { label: "賃貸借契約の開始日・終了日", value: startToEndMonth },
              { label: "家賃収入 (月)", value: monthlyFee },
              { label: "管理費収入 (月)", value: monthlyManagementFee },
              { label: "賃貸借契約開始時における収入", value: typeOfIncomeBeginningContract },
              { label: typeOfIncomeBeginningContract, value: income },
              { label: typeOfOutcomeBeginningContract, value: outcome },
            ]}
            onClickShowMoreHistory={handleClickShowMoreHistory}
          />
        </Box>
      </Box>
    </BaseTemplate>
  );
};
