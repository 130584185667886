// Validation
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const MAX_EMAIL_LENGTH = 80;

// エラーメッセージ生成関数
const createRequiredMessage = (fieldName?: string) =>
  fieldName ? `${fieldName}を入力してください。` : "入力してください。";

const createMaxLengthMessage = (length: number) => `${length}文字以内で入力してください`;

const createFormatInvalidMessage = (fieldName?: string) =>
  `※${fieldName ? `${fieldName}の` : ""}形式が正しくありません。`;

export const EMAIL_REQUIRED_MESSAGE = createRequiredMessage("メールアドレス");
export const EMAIL_MAX_LENGTH_MESSAGE = createMaxLengthMessage(MAX_EMAIL_LENGTH);
export const EMAIL_FORMAT_MESSAGE = createFormatInvalidMessage("メールアドレス");
