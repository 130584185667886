import { IconButton, Typography, makeStyles, Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    background: "white",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: "flex",
    alignItems: "center",
    minHeight: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  backButton: {
    color: theme.palette.primary.main,
    padding: 0,
  },
  titleWithBack: {
    flexGrow: 1,
    textAlign: "center",
  },
  titleWithoutBack: {
    flexGrow: 1,
  },
  actionContainer: {
    minwidth: 80,
    display: "flex",
    justifyContent: "flex-end",
  },
  emptyActionSpace: {
    width: 32,
  },
}));

interface HeaderProps {
  title: string;
  onBack?: () => void;
  ActionComponent?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ title, onBack, ActionComponent }) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      {onBack && (
        <IconButton className={classes.backButton} onClick={onBack}>
          <ArrowBackIcon className={classes.icon} />
        </IconButton>
      )}
      <Typography variant="h3" className={onBack ? classes.titleWithBack : classes.titleWithoutBack}>
        {title}
      </Typography>

      {ActionComponent ? ActionComponent : <Box className={classes.emptyActionSpace} />}
    </Box>
  );
};

export default Header;
