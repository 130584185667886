import { makeStyles, Box, Button, Typography } from "@material-ui/core";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { CustomForm } from "../../../../../../scripts/components/renewal_v1/form";
import { LatestHistoryContent } from "../../../../../../scripts/components/renewal_v1/property/edit_property_history";
import { BaseTemplate } from "../../../../../../scripts/components/renewal_v1/template/BaseTemplate";
import { parseFormattedNumber } from "../../../../../../utilities/renewal_v1/format";
import {
  getFormatCost,
  getFormatStartMonth,
  getLatestMonthlyCashflowResult,
} from "../../../../../../utilities/renewal_v1/property_edit";
import {
  PropertyHolder,
  PropertyManagementDetailSnackbarState,
  useRequestInitialLoading,
} from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { EditPropertySectionLoanInfoHistory, loanType } from "./history";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "16px auto 0px auto",
  },
}));

interface EditPropertySectionLoanInfoProps {
  propertyHolder: PropertyHolder;
  onClickPageBack: () => void;
}

export const EditPropertySectionLoanInfo: React.FC<EditPropertySectionLoanInfoProps> = ({
  propertyHolder,
  onClickPageBack,
}) => {
  const classes = useStyles();
  const { control, errors } = useForm();
  const history = useHistory();
  const requestInitialLoading = useRequestInitialLoading();
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  // 過去履歴の表示・非表示
  const [isShowHistory, setIsShowHistory] = React.useState(false);
  // 過去履歴ページに渡す情報
  const [selectedLoanType, setSelectedLoanType] = React.useState<loanType | null>(null);
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);

  const fp = PropallyApiFactory();
  const handleOnSave = async () => {
    const propertyState = {
      ...propertyHolder.property,
      payment_period: Number(control.getValues("payment_period")),
      loan_amount: parseFormattedNumber(control.getValues("loan_amount")),
    };
    try {
      const data = await fp.v1PropertiesPropertyIdPatch(selected_property.id, propertyState, {
        withCredentials: true,
      });
      if (data.status === 200) {
        setManagementDetailSnackBarState({
          message: "物件情報を変更しました",
          status: "success",
          isOpen: true,
        });
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
    }
    history.push("/management/detail");
  };

  /*
    各種コストの取得
  */

  //  借入利率変動
  // loanPrepayment を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultLoanInterestRatePercentage = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(
      propertyHolder.user_input_monthly_cashflow_results,
      "loan_interest_rate_percentage"
    );
  }, [propertyHolder.user_input_monthly_cashflow_results]);

  const loanInterestRatePercentage =
    latestMonthlyCashflowResultLoanInterestRatePercentage?.loan_interest_rate_percentage
      ? `${latestMonthlyCashflowResultLoanInterestRatePercentage.loan_interest_rate_percentage}%`
      : "-%";

  // 繰上返済
  // loanPrepayment を含む最新のMonthlyCashflowResultを取得
  const latestMonthlyCashflowResultLoanPrepayment = React.useMemo(() => {
    return getLatestMonthlyCashflowResult(propertyHolder.user_input_monthly_cashflow_results, "loan_prepayment");
  }, [propertyHolder.user_input_monthly_cashflow_results]);

  const loanPrepayment = React.useMemo(
    () => getFormatCost(latestMonthlyCashflowResultLoanPrepayment?.loan_prepayment),
    [latestMonthlyCashflowResultLoanPrepayment]
  );

  // 返済方式辞書
  const loanPrepaymentMethodMap = {
    ReduceLoanAmount: "返済額軽減型",
    ReduceLoanPaymentPeriod: "返済期間短縮型",
  };
  const loanPrepaymentMethod = latestMonthlyCashflowResultLoanPrepayment?.loan_prepayment_method
    ? loanPrepaymentMethodMap[latestMonthlyCashflowResultLoanPrepayment.loan_prepayment_method]
    : "-";

  // 過去の履歴
  const onClickPageBackFromHistory = () => {
    setIsShowHistory(false);
  };
  const handleClickShowMoreHistory = (loanType: loanType) => {
    setSelectedLoanType(loanType);
    setIsShowHistory(true);
  };
  if (isShowHistory) {
    return (
      <EditPropertySectionLoanInfoHistory loanType={selectedLoanType} onClickPageBack={onClickPageBackFromHistory} />
    );
  }

  return (
    <BaseTemplate
      headerTitle="借入情報"
      onBack={onClickPageBack}
      HeaderActionComponent={
        <Button color="primary" onClick={handleOnSave}>
          <Typography variant="h3">保存</Typography>
        </Button>
      }
    >
      <Box>
        <Box className={classes.content}>
          <Box mb={4}>
            <Controller
              name="payment_period"
              control={control}
              rules={{ required: "入力してください。" }}
              defaultValue={propertyHolder.property.payment_period || ""}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="返済年数"
                  required
                  error={!!errors.payment_period}
                  errorText={errors.payment_period?.message}
                  placeHolder="30"
                  unitSuffix="年"
                />
              )}
            />

            <Controller
              name="loan_amount"
              control={control}
              rules={{ required: "入力してください。" }}
              defaultValue={propertyHolder.property.loan_amount || ""}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="ローン借入金額"
                  required
                  error={!!errors.loan_amount}
                  errorText={errors.loan_amount?.message}
                  placeHolder="100,000,000"
                  unitSuffix="円"
                  formatNumeric
                />
              )}
            />
          </Box>

          {/* 借入利率変動 */}
          <LatestHistoryContent
            title="借入利率変動"
            contents={[
              {
                label: "支払開始月",
                value: getFormatStartMonth(latestMonthlyCashflowResultLoanInterestRatePercentage),
              },
              { label: "借入利率 (年利)", value: loanInterestRatePercentage },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("借入利率変動")}
          />

          {/* 繰上返済 */}
          <LatestHistoryContent
            title="繰上返済"
            contents={[
              { label: "支払開始月", value: getFormatStartMonth(latestMonthlyCashflowResultLoanPrepayment) },
              { label: "返済金額", value: loanPrepayment },
              { label: "返済方式", value: loanPrepaymentMethod },
            ]}
            onClickShowMoreHistory={() => handleClickShowMoreHistory("繰上返済")}
          />
        </Box>
      </Box>
    </BaseTemplate>
  );
};
