import {
  Box,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { BarChart, YAxis, XAxis, Bar } from "recharts";

import { PropertyType } from "../../../api/generated";
import { PropertyHolder } from "../../../view_models/atoms";
import { CashflowResultWithLatestResult } from "../../../view_models/balancesheet_selectors";

const useStyles = makeStyles((theme) => ({
  totalPrice: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: "bold",
  },
  incomeHeading: {
    color: theme.palette.chart.income.primary,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
  },
  expenseHeading: {
    color: theme.palette.chart.expense.primary,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
  },
  accordion: {
    background: "transparent",
    boxShadow: "none",
    border: "none",
    "&.Mui-expanded": {
      margin: "12px 0",
      "&:first-child": {
        margin: 0,
      },
    },
    "&:before": {
      display: "none",
    },
  },
  accordionTitle: {
    color: theme.palette.text.secondary,
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(20),
  },
  accordionSummary: {
    padding: 0,
    "&.Mui-expanded": {
      minHeight: "48px", // 閉じている時と同じサイズ
    },
  },
  summaryContent: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  cashflowSummary: {
    flexDirection: "column",
  },
  accordionContent: {
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionDetail: {
    flexDirection: "column",
    gap: theme.spacing(1),
    alignItems: "flex-end",
  },
  detailRow: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    minWidth: "80%",
  },
  detailPrice: {
    minWidth: "80px",
  },
}));

type CashFlowSectionProps = {
  thisMonthResult: CashflowResultWithLatestResult;
  properties: PropertyHolder[];
  loans: {
    number_of_loan_contracts: number;
    total_amount: number;
    monthly_payment_amount: number;
    total_loan_remaining: number;
    properties: {
      id: string;
      name: string;
      remaining_loan_amount: number;
    }[];
  };
};

export const CashFlowSection = ({ thisMonthResult, properties, loans }: CashFlowSectionProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const loan_repayment =
    thisMonthResult.loan_principal + thisMonthResult.loan_interest + thisMonthResult.loan_prepayment;

  // 収入項目の定義
  const income_items = [
    {
      key: "家賃収入",
      color: theme.palette.chart.income.darker,
      value: thisMonthResult.rent_income,
    },
    {
      key: "管理費収入",
      color: theme.palette.chart.income.dark,
      value: thisMonthResult.management_fee_income,
    },
  ];

  const expense_items = [
    {
      key: "ローン返済",
      color: theme.palette.chart.expense.darker,
      value: loan_repayment,
    },
    {
      key: "建物管理費",
      color: theme.palette.chart.expense.dark,
      value: thisMonthResult.building_management_cost,
    },
    {
      key: "修繕積立費",
      color: theme.palette.chart.expense.light,
      value: thisMonthResult.repair_fund_cost,
    },
    {
      key: "賃貸管理費",
      color: theme.palette.chart.expense.lighter,
      value: thisMonthResult.management_cost,
    },

    {
      key: "その他経費",
      color: theme.palette.chart.expense.lightest,
      value: thisMonthResult.misc_cost,
    },
  ];

  // 収入グラフ用データ
  const incomeData = [
    {
      name: "収入",
      ...income_items.reduce(
        (acc, item) => ({
          ...acc,
          [item.key]: item.value,
        }),
        {}
      ),
    },
  ];

  // 支出グラフ用データ
  const expenseData = [
    {
      name: "支出",
      ...expense_items.reduce(
        (acc, item) => ({
          ...acc,
          [item.key]: item.value,
        }),
        {}
      ),
    },
  ];

  // 収入
  const income = Math.round(thisMonthResult.rent_income + thisMonthResult.management_fee_income);

  // 支出
  const expense = Math.round(
    thisMonthResult.loan_principal +
      thisMonthResult.loan_interest +
      thisMonthResult.loan_prepayment +
      thisMonthResult.building_management_cost +
      thisMonthResult.repair_fund_cost +
      thisMonthResult.management_cost +
      thisMonthResult.misc_cost
  );

  // 収支
  const totalCashFlow = Math.round(income - expense);

  // 資産合計
  const totalPriceOfProperty = properties.reduce((sum, property) => sum + property.property.total_price_of_property, 0);

  // ローン残高
  const totalLoanBalance = Math.round(loans.total_loan_remaining);

  // 棒グラフを相対表示するために最大値を計算
  const maxValue = Math.max(income, expense);

  return (
    <Paper className="chart-section">
      <Box p={2} id="tour-step1">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gridGap="16px">
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Typography variant="h4">今月の合計収支</Typography>
            </Box>
            <Price value={totalCashFlow} className={classes.totalPrice} />
          </Box>
          {/* サンプル物件登録中の場合は、サンプル物件を表示している旨文言表示する */}
          {properties.some((propertyHolder) => propertyHolder.property.property_type === PropertyType.NUMBER_3) && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h5">
                サンプル物件表示中
              </Typography>
              <Typography variant="h6">サンプル物件はいつでも物件管理より削除できます</Typography>
            </Box>
          )}
          <Box display="flex" flexDirection="column" width="100%">
            <Box display="flex">
              {/* 収入の棒グラフ */}
              <BarChart width={67} height={210} barSize={52} data={incomeData}>
                <YAxis domain={[0, maxValue]} hide />
                <XAxis dataKey="name" axisLine={false} tickLine={false} />
                {income_items.map((item) => (
                  <Bar key={item.key} name={item.key} dataKey={item.key} stackId="収入" fill={item.color} />
                ))}
              </BarChart>
              {/* 支出の棒グラフ */}
              <BarChart width={67} height={210} barSize={52} data={expenseData}>
                <YAxis domain={[0, maxValue]} hide />
                <XAxis dataKey="name" axisLine={false} tickLine={false} />
                {expense_items.map((item) => (
                  <Bar key={item.key} name={item.key} dataKey={item.key} stackId="支出" fill={item.color} />
                ))}
              </BarChart>
              <Box flex={1}>
                <Accordion className={classes.accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                      root: classes.accordionSummary,
                      content: `${classes.accordionContent} ${classes.cashflowSummary}`,
                    }}
                  >
                    <Typography className={classes.accordionTitle}>収入合計</Typography>
                    <Price value={income} className={classes.incomeHeading} />
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetail}>
                    {income_items.map((item) => (
                      <Box key={item.key} display="flex" justifyContent="space-between" width="100%">
                        <LegendDot color={item.color} label={item.key} />
                        <Price value={item.value} unitSize={1} />
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Divider />
                <Accordion className={classes.accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                      root: classes.accordionSummary,
                      content: `${classes.accordionContent} ${classes.cashflowSummary}`,
                    }}
                  >
                    <Typography className={classes.accordionTitle}>支出合計</Typography>
                    <Price value={expense} className={classes.expenseHeading} />
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetail}>
                    {expense_items.map((item) => (
                      <Box key={item.key} display="flex" justifyContent="space-between" width="100%">
                        <LegendDot color={item.color} label={item.key} />
                        <Price value={item.value} unitSize={1} />
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Divider />
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: classes.accordionSummary,
                  content: `${classes.accordionContent} ${classes.summaryContent}`,
                }}
              >
                <Typography className={classes.accordionTitle}>資産合計</Typography>
                <Price value={totalPriceOfProperty} className={classes.accordionHeading} />
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetail}>
                {properties.map((property) => (
                  <Box key={property.id} className={classes.detailRow}>
                    <Typography variant="body2">{property.property.name}</Typography>
                    <Price
                      value={property.property.total_price_of_property}
                      unitSize={1}
                      className={classes.detailPrice}
                    />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: classes.accordionSummary,
                  content: `${classes.accordionContent} ${classes.summaryContent}`,
                }}
              >
                <Typography className={classes.accordionTitle}>ローン合計</Typography>
                <Price value={totalLoanBalance} className={classes.accordionHeading} />
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetail}>
                {loans.properties.map((property) => (
                  <Box key={property.id} className={classes.detailRow}>
                    <Typography variant="body2">{property.name}</Typography>
                    <Price value={property.remaining_loan_amount} unitSize={1} className={classes.detailPrice} />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
            <Divider />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

// 金額コンポーネント
const useStylesPrice = makeStyles<Theme, { unitSize: number }>(() => ({
  label: {
    display: "inline",
    overflowWrap: "anywhere",
    textAlign: "right",
  },
  unit: {
    fontSize: ({ unitSize }) => `${unitSize}em`,
    whiteSpace: "nowrap",
  },
}));

type PriceProps = { value: number; className?: string; unitSize?: number };

const Price = ({ value, className, unitSize = 0.8 }: PriceProps) => {
  const classes = useStylesPrice({ unitSize });

  return (
    <Typography variant="body2" className={`${className} ${classes.label}`}>
      <span className={classes.unit}>¥ </span>
      {Math.round(value).toLocaleString("ja-JP")}
    </Typography>
  );
};

// 凡例コンポーネント
const useStylesLegend = makeStyles<Theme, { color: string }>(() => ({
  legendItem: {
    display: "flex",
    alignItems: "center",
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    marginRight: "2px",
    backgroundColor: ({ color }) => color,
  },
}));

type LegendProps = { color: string; label: string };

const LegendDot = ({ color, label }: LegendProps) => {
  const classes = useStylesLegend({ color });

  return (
    <div className={classes.legendItem}>
      <div className={classes.dot} style={{ backgroundColor: color }} />
      <Typography variant="body2">{label}</Typography>
    </div>
  );
};
