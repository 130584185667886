import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import * as React from "react";
import { useRecoilValue } from "recoil";

import { PROPERTY_TYPE_VALUES } from "../../../api/mappings/property";
import { AppEventTokenEnum, isFirstAccess, sendEventToAppMeasurementTool } from "../../../utilities/capacitor_logic";
import { UserState } from "../../../view_models/atoms";

const useStyles = makeStyles((theme) => {
  const PC_WIDTH = 716;
  const SP_WIDTH = 390;

  return {
    root: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.grey[50],
      height: "100vh",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: theme.palette.background.paper,
        height: 0,
      },
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: "3px",
    },
    tabs: {
      display: "flex",
      justifyContent: "center",
      borderBottom: `0.5px solid ${theme.palette.grey[300]}`,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(0),
      },
    },
    tab: {
      flexGrow: 1,
      textAlign: "center",
      borderBottom: "3px solid transparent",
      width: PC_WIDTH / 2,
      [theme.breakpoints.down("sm")]: {
        width: SP_WIDTH / 2,
      },
    },
    tabLabel: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
    tabSelected: {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    tabPanel: {
      maxWidth: PC_WIDTH,
      width: "100%",
      backgroundColor: theme.palette.grey[50],
    },
  };
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps): React.JSX.Element {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
interface props {
  firstTabLabel: string;
  firstTabChildren: React.ReactNode;
  secondTabLabel: string;
  secondTabChildren: React.ReactNode;
}

export const BaseTwoColumnTab: React.FC<props> = ({
  firstTabLabel,
  firstTabChildren,
  secondTabLabel,
  secondTabChildren,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const user = useRecoilValue(UserState);

  const handleChange = (event: React.ChangeEvent, newValue: number) => {
    if (newValue === PROPERTY_TYPE_VALUES.owned) {
      sendEventToAppMeasurementTool(
        isFirstAccess(user?.created_at)
          ? AppEventTokenEnum.TabOwnPropertyFirstAccess
          : AppEventTokenEnum.TabOwnPropertySecondOnwardsAccess
      );
    } else if (newValue === PROPERTY_TYPE_VALUES.consideration) {
      sendEventToAppMeasurementTool(
        isFirstAccess(user?.created_at)
          ? AppEventTokenEnum.TabConsiderationPropertyFirstAccess
          : AppEventTokenEnum.TabConsiderationSecondOnwardsAccess
      );
    }
    setValue(newValue);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.tabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{ indicator: classes.indicator }}
          aria-label="simple tabs example"
          centered
          variant="fullWidth"
          textColor="primary"
        >
          <Tab
            label={firstTabLabel}
            classes={{ textColorPrimary: classes.tabLabel, selected: classes.tabSelected }}
            className={classes.tab}
            fullWidth
          />
          <Tab
            label={secondTabLabel}
            classes={{ textColorPrimary: classes.tabLabel, selected: classes.tabSelected }}
            className={classes.tab}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {firstTabChildren}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {secondTabChildren}
      </TabPanel>
    </Box>
  );
};
