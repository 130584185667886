import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import * as React from "react";

import { UserUpdate, UserUpdateWayToProposeEnum } from "../../../../api/generated";
import { usePatchUser } from "../../../../hooks/usePatchUser";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { SettingItemsProps } from "../SettingsPage";

export const DeleteAccount: React.FC<SettingItemsProps> = ({ user }) => {
  const { patchUser } = usePatchUser();

  const handleClick = () => {
    const request: UserUpdate = {
      ...user,
      withdrawal_intended_at: dayjs().format(),
      way_to_propose: user.way_to_propose as unknown as UserUpdateWayToProposeEnum,
    };
    patchUser(request);
    window.open("https://form.run/@propally-1619689827", "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Box mb={4} display="flex" flexDirection="column" textAlign="center" style={{ gap: "12px" }}>
        <Typography variant="h3">退会手続き</Typography>
        <Typography>退会に関しては以下の退会申請フォームより申請ください。</Typography>

        <Typography>
          担当者が申請内容を確認し、アカウントの削除後、
          <br />
          その旨をメールにて通知致します。
        </Typography>

        <Typography>
          <Typography component="span" color="error">
            退会手続きをされますと登録データは全て削除されます
          </Typography>
          ので、ご注意ください。
        </Typography>
      </Box>
      <Box justifyContent="center" display="flex">
        <CustomButton customVariant="filled" onClick={handleClick}>
          退会申請フォームへ
        </CustomButton>
      </Box>
    </>
  );
};
