import { Capacitor } from "@capacitor/core";
import {
  Box,
  Button,
  Card,
  IconButton,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GetAppIcon from "@material-ui/icons/GetApp";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useRecoilValue } from "recoil";

import { Document } from "../../../../api/generated";
import { PropallyApiFactory } from "../../../../module/custom_api_factory";
import Header from "../../../../scripts/components/renewal_v1/header";
import { DeleteDocumentModal } from "../../../../scripts/components/renewal_v1/property/delete_document_modal";
import { NoDocumentComponent } from "../../../../scripts/components/renewal_v1/property/no_document";
import { BaseTemplate } from "../../../../scripts/components/renewal_v1/template/BaseTemplate";
import { fileToBase64EncodedImage } from "../../../../utilities/file_to_base64_encoded_image";
import { useDevice } from "../../../../utilities/sp/use_device";
import { SelectedProperty } from "../../../../view_models/property_selectors";
import { DocumentTypeOptions } from "../const";

const useStyles = makeStyles((theme) => ({
  background: {
    textAlign: "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(7),
    },
  },
  fileList: {
    height: "70vh",
  },
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  file: {
    opacity: "0",
    appearance: "none",
    position: "absolute",
  },
  icon: {
    padding: 0,
  },
  image: {
    width: "100%",
  },
}));

type Column = {
  id: string;
  label: string;
  minWidth?: number;
};

const columns: Column[] = [
  {
    id: "name",
    label: "ファイル名",
    minWidth: 170,
  },
  {
    id: "created_at",
    label: "作成日",
    minWidth: 110,
  },
  {
    id: "download",
    label: "",
    minWidth: 5,
  },
  {
    id: "operation",
    label: "",
    minWidth: 5,
  },
];

export const PropertyDocumentListPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isMobile } = useDevice();
  const { id } = useParams<{ id?: string }>();
  const selected_property = useRecoilValue(SelectedProperty);
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [selectedDocument, setSelectedDocument] = React.useState<Document>(null);
  // 削除モーダルの表示状態
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = React.useState(false);
  // テーブル関連
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const fp = PropallyApiFactory();
  const documentId = React.useMemo(() => {
    if (isNaN(Number(id))) {
      history.goBack();
    } else {
      return Number(id);
    }
  }, [id]);

  const onClickPageBack = () => {
    history.push("/management/detail");
  };

  /**
   * ファイル一覧取得
   */
  const getDocuments = async () => {
    const fp = PropallyApiFactory();
    try {
      const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(selected_property.id, documentId, {
        withCredentials: true,
      });
      setDocuments(data);
    } catch (e) {
      console.error(e);
      return;
    }
  };
  // ファイル一覧取得
  React.useEffect(() => {
    getDocuments();
  }, [documentId]);

  const documentName = DocumentTypeOptions.find((option) => option.value === documentId)?.label;

  // テーブル
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (file) {
      const base64_encoded_image = await fileToBase64EncodedImage(file);
      const fp = PropallyApiFactory();
      try {
        await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost(
          selected_property.id,
          documentId,
          base64_encoded_image,
          {
            withCredentials: true,
          }
        );
        await getDocuments();
      } catch (e) {
        console.error(e);
        return;
      } finally {
        // この処理を行わないとリロードしないと再アップロードできなくなる
        e.target.value = "";
      }
    }
  };

  // ダウンロード処理
  const downloadFile = (fileBlob: any, fileName: string) => {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      //  IE向け
      (window.navigator as any).msSaveOrOpenBlob(fileBlob, fileName);
    } else {
      const url = URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  // スマートフォン向けのダウンロード処理として、ダウンロード用リンクをブラウザで開く
  const downloadFileForSP = async (response: any) => {
    try {
      window.location.href = response;
    } catch (e) {
      console.error(e);
    }
  };

  // ダウンロードボタンを押したときの処理
  const onClickDownload = async (document: Document) => {
    try {
      if (Capacitor.isNativePlatform()) {
        const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdUrlGet(
          selected_property.id,
          documentId,
          document?.id,
          document?.name,
          {
            withCredentials: true,
          }
        );
        downloadFileForSP(data);
      } else {
        const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(
          selected_property.id,
          documentId,
          document?.id,
          {
            responseType: "blob",
            withCredentials: true,
          }
        );
        downloadFile(data, document.name);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // 削除アイコンクリック時にモーダルを開く
  const handleOpenModal = React.useCallback(
    (document: Document) => {
      setSelectedDocument(document);
      setOpenDeleteDocumentModal(true);
    },
    [setOpenDeleteDocumentModal]
  );

  // モーダルの削除ボタンクリック時の処理
  const onClickDeleteIcon = React.useCallback(async () => {
    try {
      await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete(
        selected_property.id,
        documentId,
        selectedDocument.id,
        {
          withCredentials: true,
        }
      );
      getDocuments();
      setSelectedDocument(null);
    } catch (e) {
      console.error(e);
    }
  }, [selected_property.id, documentId, selectedDocument]);

  return (
    <BaseTemplate headerTitle={documentName} onBack={onClickPageBack}>
      {/* ドキュメントがない時 */}
      {documents.length === 0 ? (
        <Box className={classes.background}>
          {!isMobile && <Header title={documentName} onBack={onClickPageBack} />}
          <Box
            width={isMobile ? "auto" : "400px"}
            margin="0 auto"
            px={3}
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <NoDocumentComponent onChangeFile={handleFileInput} />
          </Box>
        </Box>
      ) : (
        <Box className={classes.fileList}>
          {/** アップロードボタン */}
          <Box display="flex" justifyContent="center">
            <Button color="primary" variant="contained">
              書類をアップロードする
              <Input
                className={classes.file}
                type="file"
                name="image"
                inputProps={{ accept: "image/*,application/pdf" }}
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFileInput(e);
                }}
              />
            </Button>
          </Box>
          <Box height="15px" />
          <Typography variant="h3">アップロード済みのファイル</Typography>
          <Card>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="left" style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          <TableCell key="name" align="left">
                            {row.name}
                          </TableCell>
                          <TableCell key="created_at" align="left">
                            {dayjs(row.created_at).format("YYYY/MM/DD")}
                          </TableCell>
                          <TableCell key="download" align="left">
                            <IconButton
                              aria-label="download"
                              className={classes.icon}
                              onClick={() => onClickDownload(row)}
                              // onClick={() => handleDocumentSelect(row)}
                            >
                              <label htmlFor="download-file">
                                <GetAppIcon
                                  style={{
                                    color: "#fff",
                                    background: "#009578",
                                    borderRadius: "50%",
                                    padding: "3px",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </label>
                            </IconButton>
                          </TableCell>
                          <TableCell key="operation" align="left">
                            <IconButton
                              aria-label="delete"
                              className={classes.icon}
                              onClick={() => handleOpenModal(row)}
                            >
                              <label htmlFor="delete-file">
                                <DeleteForeverIcon
                                  style={{
                                    color: "#fff",
                                    background: "#DE351F",
                                    borderRadius: "50%",
                                    padding: "3px",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </label>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={documents.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}から${to} 件 / ${count}件中`;
                }}
                labelRowsPerPage={<>表示件数</>}
              />
            </Paper>
          </Card>
          {openDeleteDocumentModal && (
            <DeleteDocumentModal
              onClose={() => setOpenDeleteDocumentModal(false)}
              onClickCancel={() => setOpenDeleteDocumentModal(false)}
              onClickDelete={onClickDeleteIcon}
            />
          )}
        </Box>
      )}
    </BaseTemplate>
  );
};
