import { Accordion, AccordionSummary, Typography, AccordionDetails, makeStyles, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from "recoil";

import { Document, DocumentType, MonthlyCashflowResultSumByItem } from "../../../../api/generated";
import { NEW_MANAGEMENT_TYPE_KEY_VALUES } from "../../../../api/mappings/property";
import { PropallyApiFactory } from "../../../../module/custom_api_factory";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { PropertyEditPageState } from "../../../../view_models/atoms";
import { PropertyIdToThisMonthlyBalanceSheet } from "../../../../view_models/balancesheet_selectors";
import { SelectedProperty } from "../../../../view_models/property_selectors";
import { Loading } from "../../../loading";
import { DocumentTypeOption } from "../types";

import { AccordionDetailItemRow } from "./accordion_detail_item_row";

export enum ExpandItem {
  PropertyInfo = "propertyInfo",
  PurchaseInfo = "purchaseInfo",
  Document = "document",
}
type expandItem = ExpandItem | "";

const useStyles = makeStyles((theme) => ({
  expanded: {
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  summary: {
    maxWidth: 640,
    margin: "0 auto",
    padding: 0,
    height: 72,
    [theme.breakpoints.down("sm")]: {
      height: 56,
      padding: "0 24px",
    },
  },
  detail: {
    padding: 0,
    marginBottom: 56,
    backgroundColor: theme.palette.grey[50],
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  background: {
    width: "100%",
    maxWidth: 640,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
    },
  },
}));

const documentTypeOptions: DocumentTypeOption[] = [
  {
    value: DocumentType.NUMBER_0,
    label: "マイソク・物件オファー資料",
  },
  {
    value: DocumentType.NUMBER_1,
    label: "物件パンフレット",
  },
  {
    value: DocumentType.NUMBER_2,
    label: "売買契約書",
  },
  {
    value: DocumentType.NUMBER_3,
    label: "重要事項説明書",
  },
  {
    value: DocumentType.NUMBER_4,
    label: "金銭消費貸借契約書",
  },
  {
    value: DocumentType.NUMBER_5,
    label: "返済予定表",
  },
  {
    value: DocumentType.NUMBER_6,
    label: "賃貸借契約書",
  },
  {
    value: DocumentType.NUMBER_7,
    label: "賃貸管理委託契約書",
  },
  {
    value: DocumentType.NUMBER_8,
    label: "保険証券",
  },
  {
    value: DocumentType.NUMBER_9,
    label: "納税通知書（固定資産税・都市計画税）",
  },
  {
    value: DocumentType.NUMBER_10,
    label: "納税通知書（不動産取得税）",
  },
  {
    value: DocumentType.NUMBER_11,
    label: "その他",
  },
];

export const ManagementDetailPropertyInfo: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState<expandItem>("");
  const property = useRecoilValue(SelectedProperty);
  const [documents, setDocuments] = React.useState<Map<DocumentType, Document[]>>(null);
  const [monthlyCashflowResultSumByItem, setMonthlyCashflowResultSumByItem] =
    React.useState<MonthlyCashflowResultSumByItem>({
      property_tax_sum: 0,
      large_scale_repair_fund_cost_sum: 0,
      misc_onetime_cost_sum: 0,
    });
  // useRecoilCallbackを使用して非同期の状態更新を行う
  const updatePropertyEditPageState = useRecoilCallback(({ snapshot, set }) => async (newState) => {
    set(PropertyEditPageState, newState);
    // 状態が更新されるのを待つ
    await snapshot.getPromise(PropertyEditPageState);
  });

  // ドキュメントの一覧取得
  const getDocuments = async () => {
    const fp = PropallyApiFactory();
    try {
      const newDocuments = new Map();
      await Promise.all(
        documentTypeOptions.map(async (documentType) => {
          const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(
            property.id,
            documentType.value,
            {
              withCredentials: true,
            }
          );
          newDocuments.set(documentType.value, data);
        })
      );
      setDocuments(newDocuments);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  // 単発でかかる経費の項目ごとの合計取得
  const getMonthlyCashflowResultSumByItem = () => {
    PropallyApiFactory()
      .v1PropertiesPropertyIdMonthlyCashflowResultSumByItemGet(property.id, { withCredentials: true })
      .then((res) => setMonthlyCashflowResultSumByItem(res.data));
  };

  // 物件詳細情報編集ボタンクリック時
  const onClickEditProrpertyInfo = async () => {
    await updatePropertyEditPageState("property_info");
    history.push("/edit_property");
  };

  // 購入時情報編集ボタンクリック時
  const onClickEditPurchaseInfo = async () => {
    await updatePropertyEditPageState("purchase_info");
    history.push("/edit_property");
  };

  React.useEffect(() => {
    // ドキュメントの取得
    if (!documents) {
      getDocuments();
    }
    getMonthlyCashflowResultSumByItem();
  }, [property.id]);

  const this_month_sheet_map_loadable = useRecoilValueLoadable(PropertyIdToThisMonthlyBalanceSheet(null));
  if (this_month_sheet_map_loadable.state === "loading") {
    return <Loading />;
  }
  if (this_month_sheet_map_loadable.state === "hasError") {
    throw this_month_sheet_map_loadable.contents;
  }
  // const this_month_sheet = this_month_sheet_map_loadable.contents.get(property.id);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // 価格を万円表記に変換
  const getPriceFormat = (price: number) => {
    return `${Math.round(price / 10000).toLocaleString()}万円`;
  };

  return (
    <Box mb={8}>
      {/* 物件情報 */}
      <Accordion
        expanded={expanded === ExpandItem.PropertyInfo}
        onChange={handleAccordionChange(ExpandItem.PropertyInfo)}
        classes={{ root: classes.expanded }}
        square
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
          <Typography variant="h4" color="textPrimary">
            物件詳細
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detail}>
          <Box className={classes.background}>
            {/* 物件詳細 */}
            <AccordionDetailItemRow title="マンション・アパート名" value={property.property.name} />
            <AccordionDetailItemRow title="号室" value={property.property.room_number} />
            <AccordionDetailItemRow title="所在階" value={`${property.property.floor}階`} />
            <AccordionDetailItemRow title="都道府県" value={property.property.prefecture} />
            <AccordionDetailItemRow title="市区町村" value={property.property.city} />
            <AccordionDetailItemRow title="番地" value={property.property.town ? property.property.town : "-"} />
            <AccordionDetailItemRow
              title="路線名"
              value={property.property.railway ? property.property.railway : "-"}
            />
            <AccordionDetailItemRow
              title="駅名"
              value={property.property.station ? property.property.station : "-駅"}
            />
            <AccordionDetailItemRow
              title="駅徒歩"
              value={property.property.walk ? `${property.property.walk}分` : "-分"}
            />
            <AccordionDetailItemRow
              title="築年"
              value={
                property.property.built_at ? `${dayjs().year() - dayjs(property.property.built_at).year()}年` : "-年"
              }
            />
            <AccordionDetailItemRow
              title="占有面積"
              value={property.property.occupied_area_m2 ? `${property.property.occupied_area_m2}㎡` : "-㎡"}
            />
            <AccordionDetailItemRow title="間取り" value={property.property.layout} />
            <AccordionDetailItemRow
              title="管理形態"
              value={
                NEW_MANAGEMENT_TYPE_KEY_VALUES.find((item) => item.key === property.property.management_type).value
              }
            />
            <AccordionDetailItemRow title="構造" value={property.property.structure} />
            <AccordionDetailItemRow
              title="固定資産税・都市計画税 (総和)"
              value={
                monthlyCashflowResultSumByItem.property_tax_sum
                  ? `${monthlyCashflowResultSumByItem.property_tax_sum.toLocaleString()}円`
                  : "-円"
              }
              showFinalTaxReturn={!monthlyCashflowResultSumByItem.property_tax_sum}
            />
            <AccordionDetailItemRow
              title="設備修繕、大規模修繕費用 (総和)"
              value={
                monthlyCashflowResultSumByItem.large_scale_repair_fund_cost_sum
                  ? `${monthlyCashflowResultSumByItem.large_scale_repair_fund_cost_sum.toLocaleString()}円`
                  : "-円"
              }
              showFinalTaxReturn={!monthlyCashflowResultSumByItem.large_scale_repair_fund_cost_sum}
            />
            {/* その他一過性の支出 */}
            <AccordionDetailItemRow
              title="その他一過性の支出 (総和)"
              value={
                monthlyCashflowResultSumByItem.misc_onetime_cost_sum
                  ? `${monthlyCashflowResultSumByItem.misc_onetime_cost_sum.toLocaleString()}円`
                  : "-円"
              }
              showFinalTaxReturn={!monthlyCashflowResultSumByItem.misc_onetime_cost_sum}
            />
            {/* 希望売却価格 */}
            <AccordionDetailItemRow
              title="希望売却価格（あれば）"
              value={
                property.property.asking_price
                  ? `${property.property.asking_price.toLocaleString()}円`
                  : "-円"
              }
            />

            <Box mb={2} justifyContent="center">
              <CustomButton customVariant="outlinedGray" isEdit onClick={onClickEditProrpertyInfo}>
                編集する
              </CustomButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* 購入時情報 */}
      <Accordion
        expanded={expanded === ExpandItem.PurchaseInfo}
        onChange={handleAccordionChange(ExpandItem.PurchaseInfo)}
        classes={{ root: classes.expanded }}
        square
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
          <Typography variant="h4" color="textPrimary">
            購入時情報
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detail}>
          <Box className={classes.background}>
            <AccordionDetailItemRow
              title="購入時期"
              value={
                property.property.bought_at ? dayjs(property.property.bought_at).format("YYYY年MM月DD日") : "未設定"
              }
            />
            <AccordionDetailItemRow
              title="購入価格（税込）"
              value={getPriceFormat(property.property.total_price_of_property)}
            />
            <AccordionDetailItemRow title="うち消費税" value={getPriceFormat(property.property.priceof_tax)} />
            <AccordionDetailItemRow
              title="うち建物代（税抜）"
              value={getPriceFormat(property.property.priceof_building)}
            />
            <AccordionDetailItemRow title="うち土地代" value={getPriceFormat(property.property.priceof_land)} />
            <AccordionDetailItemRow
              title="建物付属設備代（税抜）"
              value={
                property.property.priceof_facilities ? getPriceFormat(property.property.priceof_facilities) : "-万円"
              }
              showFinalTaxReturn={!property.property.priceof_facilities}
            />
            <AccordionDetailItemRow
              title="購入時不動産取得税"
              value={
                property.property.property_acquisition_tax
                  ? getPriceFormat(property.property.property_acquisition_tax)
                  : "未設定"
              }
              showFinalTaxReturn={!property.property.property_acquisition_tax}
            />
            <AccordionDetailItemRow
              title="不動産取得税の支払い月"
              value={
                property.property.property_acquisition_tax_payment_month
                  ? dayjs(property.property.property_acquisition_tax_payment_month).format("YYYY年MM月")
                  : "未設定"
              }
              showFinalTaxReturn={!property.property.property_acquisition_tax_payment_month}
            />
            <AccordionDetailItemRow
              title="購入時その他諸費用"
              value={
                property.property.misc_expences_at_bought
                  ? getPriceFormat(property.property.misc_expences_at_bought)
                  : "未設定"
              }
            />
            <Box mb={2} justifyContent="center">
              <CustomButton customVariant="outlinedGray" isEdit onClick={onClickEditPurchaseInfo}>
                編集する
              </CustomButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* 書類 */}
      <Accordion
        expanded={expanded === ExpandItem.Document}
        onChange={handleAccordionChange(ExpandItem.Document)}
        classes={{ root: classes.expanded }}
        square
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
          <Typography variant="h4" color="textPrimary">
            書類
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detail}>
          <Box className={classes.background}>
            {/* {documents && (
              <> */}
            <AccordionDetailItemRow
              title="物件パンフレット"
              isDocument
              hasDocument={documents?.get(DocumentType.NUMBER_1)?.length > 0}
              documentType={DocumentType.NUMBER_1}
            />
            <AccordionDetailItemRow
              title="売買契約書"
              isDocument
              hasDocument={documents?.get(DocumentType.NUMBER_2)?.length > 0}
              documentType={DocumentType.NUMBER_2}
            />
            <AccordionDetailItemRow
              title="重要事項説明書"
              isDocument
              hasDocument={documents?.get(DocumentType.NUMBER_3)?.length > 0}
              documentType={DocumentType.NUMBER_3}
            />
            <AccordionDetailItemRow
              title="賃貸借契約書"
              isDocument
              hasDocument={documents?.get(DocumentType.NUMBER_6)?.length > 0}
              documentType={DocumentType.NUMBER_6}
            />
            <AccordionDetailItemRow
              title="賃貸借管理委託契約書"
              isDocument
              hasDocument={documents?.get(DocumentType.NUMBER_7)?.length > 0}
              documentType={DocumentType.NUMBER_7}
            />
            <AccordionDetailItemRow
              title="返済予定表"
              isDocument
              hasDocument={documents?.get(DocumentType.NUMBER_5)?.length > 0}
              documentType={DocumentType.NUMBER_5}
            />
            <AccordionDetailItemRow
              title="納税通知書(固都税/不動産取得税)"
              isDocument
              hasDocument={documents?.get(DocumentType.NUMBER_10)?.length > 0}
              documentType={DocumentType.NUMBER_10}
            />
            <AccordionDetailItemRow
              title="保険証書(火災/地震)"
              isDocument
              hasDocument={documents?.get(DocumentType.NUMBER_8)?.length > 0}
              documentType={DocumentType.NUMBER_8}
            />
            <AccordionDetailItemRow
              title="その他（領収書など）"
              isDocument
              hasDocument={documents?.get(DocumentType.NUMBER_11)?.length > 0}
              documentType={DocumentType.NUMBER_11}
            />
            {/* </>
            )} */}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
