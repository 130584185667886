import { Menu, MenuItem, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}));

export interface DropDownMenuItem {
  color: "textPrimary" | "error";
  label: string;
  onClick: () => void;
}

interface DropDownMenuProps {
  anchorEl: null | HTMLElement;
  itemOptions: DropDownMenuItem[];
  onClose: () => void;
}

export const DropDownMenu: React.FC<DropDownMenuProps> = ({ anchorEl, itemOptions, onClose }) => {
  const classes = useStyles();

  const handleSettingsClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleOnClick = React.useCallback(
    (onClickFn: () => void) => {
      return () => {
        onClickFn();
        onClose();
      };
    },
    [onClose]
  );
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleSettingsClose}>
      {itemOptions.map((item, index) => (
        <MenuItem key={index} className={classes.menuItem} onClick={handleOnClick(item.onClick)}>
          <Typography variant="body1" color={item.color}>
            {item.label}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
