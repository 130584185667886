import { Box, Tabs, Tab, Badge, makeStyles } from "@material-ui/core";
import * as React from "react";

import { TabPanel } from "../two_col_tab";

const useStyles = makeStyles((theme) => {
  const PC_WIDTH = 716;
  const SP_WIDTH = 390;

  return {
    root: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.grey[50],
      height: "100vh",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: theme.palette.background.paper,
        height: 0,
      },
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: "3px",
    },
    tabs: {
      display: "flex",
      justifyContent: "center",
      borderBottom: `0.5px solid ${theme.palette.grey[300]}`,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(0),
      },
    },
    tab: {
      flexGrow: 1,
      textAlign: "center",
      borderBottom: "3px solid transparent",
      width: PC_WIDTH / 2,
      [theme.breakpoints.down("sm")]: {
        width: SP_WIDTH / 2,
      },
    },
    tabLabel: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
    tabSelected: {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    tabPanel: {
      maxWidth: PC_WIDTH,
      width: "100%",
      backgroundColor: theme.palette.grey[50],
    },
  };
});

interface props {
  firstTabLabel: string;
  firstTabChildren: React.ReactNode;
  firstTabCount: number;
  secondTabLabel: string;
  secondTabChildren: React.ReactNode;
  secondTabCount: number;
}

export const OfferTwoColumnTab: React.FC<props> = ({
  firstTabLabel,
  firstTabChildren,
  firstTabCount,
  secondTabLabel,
  secondTabChildren,
  secondTabCount,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.tabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{ indicator: classes.indicator }}
          aria-label="simple tabs example"
          centered
          variant="fullWidth"
          textColor="primary"
        >
          <Tab
            label={
              <Badge badgeContent={firstTabCount} color="error" overlap="rectangular">
                {firstTabLabel}
              </Badge>
            }
            classes={{ textColorPrimary: classes.tabLabel, selected: classes.tabSelected }}
            className={classes.tab}
            fullWidth
          />
          <Tab
            label={
              <Badge badgeContent={secondTabCount} color="error" overlap="rectangular">
                {secondTabLabel}
              </Badge>
            }
            classes={{ textColorPrimary: classes.tabLabel, selected: classes.tabSelected }}
            className={classes.tab}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {firstTabChildren}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {secondTabChildren}
      </TabPanel>
    </Box>
  );
};
