import { useState } from "react";

import { User, UserUpdate } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

export const usePatchUser = (): {
  patchUser: (request: UserUpdate) => Promise<void>;
  response: User;
  loading: boolean;
  error: string;
} => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const patchUser = async (request: UserUpdate): Promise<void> => {
    const fp = PropallyApiFactory();

    try {
      setLoading(true);
      setError("");

      const response = await fp.v1UserPatch(request, { withCredentials: true });
      setResponse(response.data);
    } catch (e) {
      if (e.response?.data.code === "INVALID_PHONE_NUMBER_AUTH_CODE") {
        setError("電話番号認証コードが不正です");
      }

      if (e.response?.data.code === "EMAIL_ALREADY_TAKEN") {
        setError("このメールアドレスは既に使用されています");
      }

      if (e.response?.status === 401 || e.response?.status === 400) {
        setError("更新に失敗しました。画面をリロードし、再度ログインしてください");
      }
      setResponse(null);
    } finally {
      setLoading(false);
    }
  };

  return { patchUser, response, loading, error };
};
