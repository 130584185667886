import { TextField, FormControl, FormHelperText, Box, TextFieldProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import CustomFormLabel from "./form_label";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  textarea: {
    resize: "both",
  },
  error: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  success: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  counter: {
    textAlign: "right",
  },
}));

interface InputProps extends Omit<TextFieldProps, "variant" | "value" | "error"> {
  label: string;
  value: string;
  error?: string;
  maxLength?: number;
  required?: boolean;
  variant?: "default" | "success";
  helperText?: string;
}

const CustomInputRef: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    value = "",
    onChange,
    error = "",
    maxLength = 100,
    required = false,
    variant = "default",
    helperText,
    placeholder,
    onBlur,
  },
  ref
) => {
  const classes = useStyles();
  const characterCount = `${value.length} / ${maxLength}`;

  return (
    <FormControl className={classes.root} error={!!error}>
      <Box display="flex" alignItems="center" mb={1}>
        <CustomFormLabel label={label} required={required} helperText={helperText} info="" />
      </Box>
      <TextField
        value={value}
        onChange={onChange}
        variant="outlined"
        multiline
        minRows={4}
        maxRows={8}
        inputProps={{ maxLength: maxLength, className: classes.textarea }}
        inputRef={ref}
        required={required}
        className={variant === "success" ? classes.success : ""}
        helperText={characterCount}
        FormHelperTextProps={{
          // className: error ? classes.error : classes.counter,
          className: classes.counter,
        }}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      {error && <FormHelperText className={classes.error}>{error}</FormHelperText>}
    </FormControl>
  );
};

const CustomInput = React.forwardRef(CustomInputRef);
export { CustomInput };

// サンプルコンポーネントでインプットを使用する
const InputExample: React.FC = () => {
  const [value, setValue] = React.useState("");
  const [, setError] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length > 100) {
      setError("エラーテキストが入ります。");
    } else {
      setError("");
    }
    setValue(newValue);
  };

  return (
    <div>
      <CustomInput label="ラベル" value={value} onChange={handleChange} required maxLength={100} />
      <CustomInput
        label="ラベル"
        value="入力済の内容が入ります。入力済の内容が入ります。入力済の内容が入ります。"
        onChange={() => {}}
        required
        maxLength={100}
      />
      <CustomInput
        label="ラベル"
        value="入力済の内容が入ります。入力済の内容が入ります。入力済の内容が入ります。"
        onChange={() => {}}
        error="エラーテキストが入ります。"
        maxLength={100}
      />
      <CustomInput label="ラベル" value="" onChange={() => {}} variant="success" maxLength={100} />
    </div>
  );
};

export default InputExample;
