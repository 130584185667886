import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import _ from "lodash";
import React, { useState } from "react";
import { SetterOrUpdater } from "recoil";

import CustomFormLabel from "./form_label";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 161,
    width: "100%",
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[300],
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400],
    },
  },
  menuPaper: {
    maxHeight: "40vh",
  },
}));

export type MultiSelectOption = {
  id: number;
  label: string;
};

export type MultiSelectProps = {
  label?: string;
  options: MultiSelectOption[];
  selectedIds: number[];
  onChange: SetterOrUpdater<number[]>;
};

export const MultiSelect: React.FC<MultiSelectProps> = ({ label, options, selectedIds, onChange }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as Array<string>;
    const edited: Array<number> = value
      .filter((v) => {
        return v !== undefined;
      })
      .map((e) => {
        return parseInt(e);
      });

    if (!_.isEqual(edited, selectedIds)) {
      onChange(edited);
    }
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {label && (
        <Box mb={1}>
          <CustomFormLabel label={label} />
        </Box>
      )}
      <Select
        multiple
        displayEmpty
        value={selectedIds}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        onChange={handleChange}
        className={classes.select}
        renderValue={() => {
          if (selectedIds.length === 0) {
            return "未選択";
          }
          return `${selectedIds.length}件選択中`;
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          PaperProps: {
            className: classes.menuPaper,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selectedIds.includes(option.id)} color="primary" />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
        <Box mt={1}>
          <Button fullWidth size="small" color="primary" onClick={() => setOpen(false)}>
            閉じる
          </Button>
        </Box>
      </Select>
    </FormControl>
  );
};
