import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { sendEventToAppMeasurementTool, isFirstAccess, AppEventTokenEnum } from "../../../../utilities/capacitor_logic";
import { SettingItemsProps } from "../SettingsPage";

export const ReportTab: React.FC<SettingItemsProps> = ({ user }) => {
  const onClickReport = () => {
    sendEventToAppMeasurementTool(
      isFirstAccess(user?.created_at)
        ? AppEventTokenEnum.ReportFirstAccess
        : AppEventTokenEnum.ReportSecondOnwardsAccess
    );
    window.open("https://form.run/@propally-1619963249", "_blank");
  };
  return (
    <>
      <Box mb={4} display="flex" flexDirection="column" textAlign="center" style={{ gap: "12px" }}>
        <Typography variant="h3">不具合報告・改善要望</Typography>
        <Typography>
          以下から不具合の報告をお願いいたします。
          <br />
          ※ブラウザが立ち上がります。
        </Typography>
      </Box>
      <Box justifyContent="center" display="flex">
        <CustomButton customVariant="filled" onClick={onClickReport}>
          不具合報告フォームへ
        </CustomButton>
      </Box>
    </>
  );
};
