import { useState } from "react";

import { PropallyApiFactory } from "../module/custom_api_factory";

interface UseApproveInvestmentProposal {
  approveInvestmentProposal: () => Promise<void>;
  loading: boolean;
  errors: string;
}

export const useApproveInvestmentProposal = (id: number): UseApproveInvestmentProposal => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>("");
  const fp = PropallyApiFactory();

  const approveInvestmentProposal = async () => {
    try {
      setLoading(true);

      const data = await fp.v1InvestmentProposalsInvestmentProposalIdApprovedPatch(
        id,
        { is_approved: true },
        { withCredentials: true }
      );

      if (data.status === 200) {
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setErrors(e);
    }
  };

  return { approveInvestmentProposal, loading, errors };
};
