import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { Settings } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ApartmentIcon from "@material-ui/icons/Apartment";
import HomeIcon from "@material-ui/icons/Home";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import LogoImage from "../../../../../assets/image/logo_gradation01.png";
import { sendEventToAppMeasurementTool, isFirstAccess, AppEventTokenEnum } from "../../../../utilities/capacitor_logic";
import { UserState } from "../../../../view_models/atoms";
import { CustomButton } from "../button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      color: theme.palette.tertiary.main,
    },
    appBar: {
      backgroundColor: theme.palette.background.paper, // AppBarの背景色を変更
      color: theme.palette.tertiary.main,
    },
    toolBar: {
      height: theme.spacing(7),
      minHeight: theme.spacing(7),
      borderBottom: `0.5px solid ${theme.palette.grey[300]}`,
    },
    logoImage: {
      width: "auto",
      height: 24,
    },
    list: {
      width: 250,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    sidebarButton: {
      width: 200,
      margin: "0 auto",
      padding: "16px 0",
    },
  })
);

interface NavigationItem {
  name: string;
  url: string;
  icon: React.JSX.Element;
  onClickLink: () => void;
}

export const ManagementAppBar: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const user = useRecoilValue(UserState);

  const onClickLinkMypage = () => {
    sendEventToAppMeasurementTool(
      isFirstAccess(user?.created_at)
        ? AppEventTokenEnum.MypageFirstAccess
        : AppEventTokenEnum.MypageSecondOnwardsAccess
    );
  };

  const onClickLinkManagement = () => {
    sendEventToAppMeasurementTool(
      isFirstAccess(user?.created_at)
        ? AppEventTokenEnum.PropertyManagementFirstAccess
        : AppEventTokenEnum.PropertyManagementSecondOnwardsAccess
    );
  };

  const onClickLinkOffer = () => {
    sendEventToAppMeasurementTool(
      isFirstAccess(user?.created_at) ? AppEventTokenEnum.OfferFirstAccess : AppEventTokenEnum.OfferSecondOnwardsAccess
    );
  };

  const onClickNearbyLink = () => {
    sendEventToAppMeasurementTool(
      isFirstAccess(user?.created_at)
        ? AppEventTokenEnum.PropertyNearbyPriceFirstAccess
        : AppEventTokenEnum.PropertyNearbyPriceSecondOnwardsAccess
    );
  };

  const onClickLinkSetting = () => {
    sendEventToAppMeasurementTool(
      isFirstAccess(user?.created_at)
        ? AppEventTokenEnum.SettingFirstAccess
        : AppEventTokenEnum.SettingSecondOnwardsAccess
    );
  };

  const navigationItems: NavigationItem[] = [
    {
      name: "ホーム",
      url: "/mypage",
      icon: <HomeIcon key={0} />,
      onClickLink: onClickLinkMypage,
    },
    {
      name: "物件管理",
      url: "/management",
      icon: <ApartmentIcon key={1} />,
      onClickLink: onClickLinkManagement,
    },
    {
      name: "オファー",
      url: "/offer",
      icon: <MailIcon key={2} />,
      onClickLink: onClickLinkOffer,
    },
    {
      name: "成約事例",
      url: "/management/detail/nearby",
      icon: <SearchIcon key={3} />,
      onClickLink: onClickNearbyLink,
    },
    {
      name: "設定",
      url: "/settings",
      icon: <Settings key={4} />,
      onClickLink: onClickLinkSetting,
    },
  ];

  const onClickToAccount = () => {
    history.push("/settings");
  };
  const toggleSidebar = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setSidebarOpen(open);
  };

  return (
    <div>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleSidebar(true)}
          >
            <MenuIcon />
          </IconButton>
          <img src={LogoImage} alt="logo" className={classes.logoImage} />
          <Typography variant="h6" className={classes.title}></Typography>
          <IconButton color="inherit" onClick={onClickToAccount}>
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={sidebarOpen} onClose={toggleSidebar(false)}>
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleSidebar(false)}
          onKeyDown={toggleSidebar(false)}
        >
          <List>
            {navigationItems.map((item) => (
              <ListItem button key={item.name} component={Link} to={item.url} onClick={() => item.onClickLink()}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
          <Box className={classes.sidebarButton}>
            <CustomButton
              customVariant="outlinedPrimary"
              onClick={() => {
                sendEventToAppMeasurementTool(
                  isFirstAccess(user?.created_at)
                    ? AppEventTokenEnum.ReportFirstAccess
                    : AppEventTokenEnum.ReportSecondOnwardsAccess
                );
                window.open("https://form.run/@propally-1619963249", "_blank");
              }}
            >
              不備報告・改善要望
            </CustomButton>
          </Box>
        </div>
      </Drawer>
    </div>
  );
};
