import { TextField, FormControl, InputAdornment, IconButton, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { AutocompleteRenderInputParams } from "@material-ui/lab";
import * as React from "react";
import { forwardRef } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import CustomFormLabel from "./form_label";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
    minWidth: 161,
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  adornment: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  labelIcon: {
    marginLeft: theme.spacing(0.5),
  },
  inputRoot: {
    paddingRight: 0,
    height: 45,
    [theme.breakpoints.down("sm")]: {
      height: 56,
    },
  },
  input: {
    padding: "12px 16px 12px 16px",
  },
  helperText: {
    marginLeft: "14px",
    marginRight: "14px",
  },
  error: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface NumericFormatCustomProps extends NumericFormatProps {
  inputRef: React.ForwardedRef<HTMLInputElement>;
}

// カンマ区切り用のreact-number-formatのコンポーネント
function NumericFormatInput(props: NumericFormatCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <NumericFormat {...other} getInputRef={inputRef} allowLeadingZeros thousandSeparator="," inputMode="decimal" />
  );
}

interface CustomFormProps {
  label?: string;
  labelLight?: string;
  value: string;
  onChange: (_event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (_event: React.FocusEvent<HTMLInputElement>) => void;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  info?: string;
  type?: "text" | "password" | "month" | "number";
  unitSuffix?: string;
  autoComplete?: string;
  errorText?: string | JSX.Element;
  placeHolder?: string;
  isShort?: boolean;
  formatNumeric?: boolean;
}

const CustomFormRef: React.ForwardRefRenderFunction<HTMLInputElement, CustomFormProps> = (
  {
    label,
    labelLight,
    value = "",
    onChange,
    onBlur,
    helperText = "",
    required = false,
    disabled = false,
    error = false,
    info = "",
    type = "text",
    unitSuffix = "",
    autoComplete = "off",
    errorText = "",
    placeHolder = "",
    isShort,
    formatNumeric = false,
  },
  ref
) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <FormControl className={classes.formControl} variant="outlined" error={error} disabled={disabled}>
      <Box display="flex" alignItems="center" mb={1}>
        {label && <CustomFormLabel label={label} required={required} helperText={helperText} info={info} />}
        {labelLight && (
          <CustomFormLabel label={label} labelLight={labelLight} required={required} helperText={helperText} />
        )}
      </Box>
      <TextField
        style={isShort ? { width: "161px" } : { width: "100%" }}
        variant="outlined"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        InputProps={{
          ...(formatNumeric
            ? {
                inputComponent: NumericFormatInput,
              }
            : {}),
          classes: { root: classes.inputRoot, input: classes.input },
          endAdornment: (
            <>
              {type === "password" && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              {unitSuffix && (
                <InputAdornment position="end" className={classes.adornment}>
                  {unitSuffix}
                </InputAdornment>
              )}
            </>
          ),
        }}
        placeholder={placeHolder}
        autoComplete={autoComplete}
        inputRef={ref}
        error={error}
        disabled={disabled}
      />
      {error && <Typography className={classes.errorMessage}>{errorText}</Typography>}
    </FormControl>
  );
};

const CustomForm = forwardRef(CustomFormRef);
export { CustomForm };

interface CustomAutoCompleteFormProps {
  params?: AutocompleteRenderInputParams;
  label?: string;
  labelLight?: string;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  info?: string;
  autoComplete?: string;
  errorText?: string | JSX.Element;
  placeHolder?: string;
  isShort?: boolean;
}

const CustomAutoCompleteFormRef: React.ForwardRefRenderFunction<HTMLInputElement, CustomAutoCompleteFormProps> = (
  {
    params,
    label,
    labelLight,
    helperText = "",
    required = false,
    disabled = false,
    error = false,
    info = "",
    errorText = "",
    placeHolder = "",
    isShort,
  },
  ref
) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} variant="outlined" error={error} disabled={disabled}>
      <Box display="flex" alignItems="center" mb={1}>
        {label && <CustomFormLabel label={label} required={required} helperText={helperText} info={info} />}
        {labelLight && (
          <CustomFormLabel label={label} labelLight={labelLight} required={required} helperText={helperText} />
        )}
      </Box>
      <TextField
        {...params}
        style={isShort ? { width: "161px" } : { width: "100%" }}
        variant="outlined"
        InputProps={{
          classes: { root: classes.inputRoot, input: classes.input },
          ...params.InputProps,
        }}
        ref={ref}
        placeholder={placeHolder}
        error={error}
        disabled={disabled}
      />
      {error && <Typography className={classes.errorMessage}>{errorText}</Typography>}
    </FormControl>
  );
};

const CustomAutoCompleteForm = forwardRef(CustomAutoCompleteFormRef);
export { CustomAutoCompleteForm };

// サンプルコンポーネントでカスタムフォームを使用する
const FormExample: React.FC = () => {
  const [value, setValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <CustomForm label="ラベル" value={value} onChange={handleChange} required placeHolder="テキスト" />
      <CustomForm
        label="ラベル"
        value={value}
        onChange={handleChange}
        helperText="テキストが入ります"
        required
        placeHolder="テキスト"
      />
      <CustomForm
        label="ラベル"
        value={value}
        onChange={handleChange}
        error
        errorText="エラーテキストが入ります"
        required
        placeHolder="テキスト"
      />
      <CustomForm
        label="ラベル"
        value={value}
        onChange={handleChange}
        type="password"
        required
        placeHolder="テキスト"
      />
      <CustomForm
        label="ラベル"
        value={value}
        onChange={handleChange}
        unitSuffix="年"
        required
        info="追加情報"
        placeHolder="テキスト"
      />
    </div>
  );
};

export default FormExample;
