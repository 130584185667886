import { useState } from "react";

import { ReinfolibPrice } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

interface UseGetNearbyPrices {
  fetchNearbyPrices: (propertyId: number) => Promise<void>;
  nearbyPrices: ReinfolibPrice[];
  loading: boolean;
}

export const useGetNearbyPrices = (): UseGetNearbyPrices => {
  const [loading, setLoading] = useState<boolean>(false);
  const [nearbyPrices, setNearbyPrices] = useState<ReinfolibPrice[]>(undefined);

  const fetchNearbyPrices = async (propertyId: number) => {
    setLoading(true);

    const fp = PropallyApiFactory();
    try {
      const data = await fp.v1PropertiesPropertyIdNearbyPricesGet(propertyId, { withCredentials: true });
      if (data.status === 200) {
        setNearbyPrices(data.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return { fetchNearbyPrices, nearbyPrices, loading };
};
