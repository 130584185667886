import { useEffect } from "react";

import { ScreenNameEnum } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

export const usePostUserAccessScreenLogs = (screenName: ScreenNameEnum): void => {
  useEffect(() => {
    const logAccess = async () => {
      const fp = PropallyApiFactory();
      await fp.v1UserAccessScreenLogsPost({ screen_name: screenName }, { withCredentials: true });
    };

    logAccess();
  }, [screenName]);
};
