import { App } from "@capacitor/app";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { NotFound } from "./pages/404";
import { EmailConfirmation } from "./pages/email_confirmation";
import { PropertyDocumentList } from "./pages/management/property_document_list";
import { NewPasswordReset } from "./pages/new_password_reset";
import { PasswordReset } from "./pages/password_reset";
// import { PropallyRecoilRoot } from "./pages/recoil_root";
// import { Registrations } from "./pages/registrations";
import { RegistrationsComplete } from "./pages/registrations_complete";
import { RegistrationsError } from "./pages/registrations_error";
import AccountRegisterBasicPage from "./pages/renewal_v1/account";
import { BalancePage } from "./pages/renewal_v1/balance";
import { ComponentTest } from "./pages/renewal_v1/component_test";
import LoginPage from "./pages/renewal_v1/login";
import AddNewConsiderationPropertyPage from "./pages/renewal_v1/management/add_new_property/consideration_property";
import AddNewOwnPropertyPage from "./pages/renewal_v1/management/add_new_property/own_property";
import { PropertyDocumentListPage } from "./pages/renewal_v1/management/documents";
import EditPropertyPage from "./pages/renewal_v1/management/edit_property";
import { ManagementPage } from "./pages/renewal_v1/management/management";
import { ManagementConsiderationDetailPage } from "./pages/renewal_v1/management/management_consideration_detail";
import { ManagementDetailPage } from "./pages/renewal_v1/management/management_detail";
import { ManagementDetailNearbyPage } from "./pages/renewal_v1/management/management_detail_nearby";
import { ManagementDetailSimulationConfigPage } from "./pages/renewal_v1/management/simulation/management_detail_simulation_config";
import { MyPage } from "./pages/renewal_v1/mypage";
import { OfferListPage } from "./pages/renewal_v1/offer";
import { OfferAssessmentDetailPage } from "./pages/renewal_v1/offer/detail/assessment_detail";
import { OfferInvestmentDetailPage } from "./pages/renewal_v1/offer/detail/investment_detail";
import OnboardingPage from "./pages/renewal_v1/onboarding";
import { RenewalV1PropallyRecoilRoot } from "./pages/renewal_v1/recoil_root";
import RegisterPage from "./pages/renewal_v1/register";
import SettingsPage from "./pages/renewal_v1/settings/SettingsPage";
import { WithAuth } from "./pages/with_auth";
// import { Routes } from "./routes";
import { theme } from "./theme";
import { PreviousLocationProvider } from "./utilities/renewal_v1/previousLocation";
import ScrollToTop from "./utilities/scroll_to_top";

export const PropallyApp: React.FC = () => {
  // Handle back-button of Android.
  // See https://capacitorjs.com/docs/apis/app#addlistenerbackbutton
  const onBackButtonPushed = ({ canGoBack }) => {
    if (canGoBack) {
      history.back();
    }
  };

  React.useEffect(() => {
    App.addListener("backButton", onBackButtonPushed);
    return () => {
      App.removeAllListeners();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {/** MEMO: Reset CSS */}
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <RecoilRoot>
          <Router>
            <ScrollToTop />
            <Switch>
              {/* <Route exact path="/login" component={Login} /> */}
              {/* <Route exact path="/registrations" component={Registrations} /> */}
              <Route exact path="/registrations/complete" component={RegistrationsComplete} />
              <Route exact path="/registrations/error" component={RegistrationsError} />
              <Route exact path="/password_reset" component={PasswordReset} />
              <Route exact path="/password_reset/new" component={NewPasswordReset} />
              <Route exact path="/email_confirmation" component={EmailConfirmation} />
              {/* renewal_v1ページ */}
              <Route exact path="/component" component={ComponentTest} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/registrations" component={RegisterPage} />
              <Route exact path={["/", "/onboarding"]} component={OnboardingPage} />

              <WithAuth>
                <RenewalV1PropallyRecoilRoot>
                  {/* <Route exact path="/property_document/:id" component={PropertyDocumentPage} /> */}
                  <Route exact path="/property_document/:id" component={PropertyDocumentListPage} />
                  <PreviousLocationProvider>
                    <Switch>
                      <Route exact path="/property_document_list" component={PropertyDocumentList} />
                      <Route exact path="/add_new_property" component={AddNewOwnPropertyPage} />
                      <Route exact path="/add_new_property/consideration" component={AddNewConsiderationPropertyPage} />
                      <Route exact path="/edit_property" component={EditPropertyPage} />
                      <Route path="/mypage" component={MyPage} />
                      <Route exact path="/first_account" component={AccountRegisterBasicPage} />
                      <Route exact path="/management" component={ManagementPage} />
                      <Route exact path="/management/detail" component={ManagementDetailPage} />
                      <Route exact path="/management/detail/nearby" component={ManagementDetailNearbyPage} />
                      <Route
                        exact
                        path="/management/detail/simulation"
                        component={ManagementDetailSimulationConfigPage}
                      />
                      <Route
                        exact
                        path="/management_consideration/detail"
                        component={ManagementConsiderationDetailPage}
                      />
                      <Route exact path="/offer" component={OfferListPage} />
                      <Route exact path="/investment/:id" component={OfferInvestmentDetailPage} />
                      <Route exact path="/assessment/:id" component={OfferAssessmentDetailPage} />
                      <Route exact path="/balance" component={BalancePage} />
                      <Route exact path="/settings" component={SettingsPage} />
                    </Switch>
                  </PreviousLocationProvider>
                </RenewalV1PropallyRecoilRoot>
              </WithAuth>
              <Route render={() => <NotFound />} />
            </Switch>
          </Router>
        </RecoilRoot>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
