import { makeStyles, Box, Grid, Typography, debounce, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Autocomplete, AutocompleteInputChangeReason, createFilterOptions } from "@material-ui/lab";
import dayjs from "dayjs";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilCallback, useSetRecoilState } from "recoil";

import {
  HowMaBuilding,
  HowMaRoom,
  IdName,
  PropertyLayoutEnum,
  PropertyManagementTypeEnum,
  PropertySalesStatusEnum,
} from "../../../../../api/generated";
import { GetCities, GetPrefectures, GetRailways, GetStations, GetTowns } from "../../../../../api/get_address";
import { NEW_MANAGEMENT_TYPE_KEY_VALUES, PROPERTY_TYPE_VALUES } from "../../../../../api/mappings/property";
import {
  convertAreaNameToCity,
  convertAreaNameToPrefecture,
  convertAreaNameToTown,
  convertFloorPlanToLayout,
} from "../../../../../logics/howma/HowMaBuildingSearchApiLogic";
import { PropallyApiFactory } from "../../../../../module/custom_api_factory";
import { CustomAutoCompleteForm, CustomForm } from "../../../../../scripts/components/renewal_v1/form";
import { CustomSelector } from "../../../../../scripts/components/renewal_v1/selecter";
import { BaseTemplate } from "../../../../../scripts/components/renewal_v1/template/BaseTemplate";
import { parseFormattedNumber } from "../../../../../utilities/renewal_v1/format";
import {
  PropertyEditPageState,
  PropertyHolder,
  PropertyManagementDetailSnackbarState,
  useRequestInitialLoading,
} from "../../../../../view_models/atoms";
import { Layout } from "../../add_new_property/const";
const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "16px auto 0px auto",
  },
  historyLink: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 8px",
  },
}));

interface EditPropertySectionPropertyInfoProps {
  propertyHolder: PropertyHolder;
  onClickPageBack: () => void;
  setSeverError: (_message: string) => void;
}

export const EditPropertySectionPropertyInfo: React.FC<EditPropertySectionPropertyInfoProps> = ({
  propertyHolder,
  onClickPageBack,
  setSeverError,
}) => {
  const classes = useStyles();
  const { control, errors, setValue, watch } = useForm({
    defaultValues: {
      name: propertyHolder.property.name ? propertyHolder.property.name : "",
      room_number: propertyHolder.property.room_number ? propertyHolder.property.room_number : "",
      floor: propertyHolder.property.floor ? propertyHolder.property.floor : "",
      prefecture: propertyHolder.property.prefecture ? propertyHolder.property.prefecture : "",
      city: propertyHolder.property.city ? propertyHolder.property.city : "",
      town: propertyHolder.property.town ? propertyHolder.property.town : "",
      railway: propertyHolder.property.railway ? propertyHolder.property.railway : "",
      station: propertyHolder.property.station ? propertyHolder.property.station : "",
      walk: propertyHolder.property.walk ? propertyHolder.property.walk : "",
      built_at: propertyHolder.property.built_at ? dayjs(propertyHolder.property.built_at).format("YYYY") : "",
      occupied_area_m2: propertyHolder.property.occupied_area_m2 ? propertyHolder.property.occupied_area_m2 : "",
      layout: propertyHolder.property.layout ? propertyHolder.property.layout : "",
      management_type: propertyHolder.property.management_type
        ? NEW_MANAGEMENT_TYPE_KEY_VALUES.find((item) => item.key === propertyHolder.property.management_type).value
        : NEW_MANAGEMENT_TYPE_KEY_VALUES[0].value,
      rental_management_company: propertyHolder.property.rental_management_company
        ? propertyHolder.property.rental_management_company
        : "",
      sales_status: propertyHolder.property.sales_status
        ? propertyHolder.property.sales_status
        : PropertySalesStatusEnum.売りに出していない,
      asking_price: propertyHolder.property.asking_price ? propertyHolder.property.asking_price : "",
      // TODO: 固定資産税・都市計画税
    },
  });
  const history = useHistory();
  const requestInitialLoading = useRequestInitialLoading();
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);
  // useRecoilCallbackを使用して非同期の状態更新を行う
  const updatePropertyEditPageState = useRecoilCallback(({ snapshot, set }) => async (newState) => {
    set(PropertyEditPageState, newState);
    // 状態が更新されるのを待つ
    await snapshot.getPromise(PropertyEditPageState);
  });

  // AutoCompleteコンポーネントでWarningを出さずに空文字を許容するために使用
  const defaultFilterOptions = createFilterOptions();
  /**
   * State
   */
  const [prefectures, setPrefectures] = React.useState<Array<string>>([]);
  const [cities, setCities] = React.useState<Array<string>>([
    propertyHolder.property.city ? propertyHolder.property.city : "",
  ]);
  const [towns, setTowns] = React.useState<Array<string>>([""]);
  const [railways, setRailways] = React.useState<Array<IdName>>([]);
  const [stations, setStations] = React.useState<Array<string>>([""]);

  /*
  マンション検索関連 state
  */
  const [buildingOptions, setBuildingOptions] = React.useState<Array<HowMaBuilding>>([]);
  const [roomOptions, setRoomOptions] = React.useState<Array<HowMaRoom>>([]);
  const [selectedHowMaBuilding, setSelectedHowMaBuilding] = React.useState<HowMaBuilding>(null);
  const [selectedHowMaRoom, setSelectedHowMaRoom] = React.useState<HowMaRoom>(null);
  const [isBuildingsSearching, setIsBuildingsSearching] = React.useState<boolean>(false);

  const watchValues = watch();

  const propertyInfo = React.useMemo(() => {
    return {
      name: watchValues.name,
      room_number: watchValues.room_number,
      floor: Number(watchValues.floor),
      prefecture: watchValues.prefecture,
      city: watchValues.city,
      town: watchValues.town,
      railway: watchValues.railway,
      station: watchValues.station,
      walk: Number(watchValues.walk),
      built_at: dayjs(`${watchValues.built_at}-01-01`).format("YYYY-MM-DD"),
      occupied_area_m2: Number(watchValues.occupied_area_m2),
      layout: watchValues.layout as PropertyLayoutEnum,
      management_type: NEW_MANAGEMENT_TYPE_KEY_VALUES.find((item) => item.value === watchValues.management_type)
        .key as PropertyManagementTypeEnum,
      sales_status: watchValues.sales_status,
      asking_price: parseFormattedNumber(watchValues.asking_price),
    };
  }, [watchValues]);

  const propertyState = React.useMemo(() => {
    return {
      ...propertyHolder.property,
      ...propertyInfo,
    };
  }, [propertyInfo]);

  /** Load prefectures and railways at once */
  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      {
        const r = await GetPrefectures();
        if (isMounted) {
          if (!r.success) {
            return setSeverError(r.error);
          }
          setPrefectures(r.prefectures);
        }
      }

      {
        const r = await GetRailways();
        if (isMounted) {
          if (!r.success) {
            return setSeverError(r.error);
          }

          setRailways(r.railways);
        }
      }
      return undefined;
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  /**
   * マンション名候補から選択した場合の自動入力処理
   * ※市区町村, 番地, 駅名の自動入力はAutocompleteの選択肢がセットされてから実施するため、それぞれの各マスタuseEffect箇所でそれぞれ実施
   */
  React.useEffect(() => {
    if (!selectedHowMaBuilding) return;
    setValue("name", selectedHowMaBuilding.name);
    setValue("prefecture", convertAreaNameToPrefecture(selectedHowMaBuilding.area.name, prefectures));
    setValue("railway", railways.find((v) => v.id === selectedHowMaBuilding.station?.line_cd)?.name ?? "");
    setValue("built_at", selectedHowMaBuilding.built_at_year.toString());
  }, [selectedHowMaBuilding]);

  /** 号室候補から選択した場合の自動入力処理 */
  React.useEffect(() => {
    if (!selectedHowMaRoom) return;
    setValue("room_number", selectedHowMaRoom.name);
    setValue("floor", selectedHowMaRoom.floor_number);
    setValue("occupied_area_m2", selectedHowMaRoom.m2);
    setValue("layout", convertFloorPlanToLayout(selectedHowMaRoom.floor_plan));
  }, [selectedHowMaRoom]);

  /** Load cities for each time prefecture is selected */
  React.useEffect(() => {
    if (!watchValues.prefecture) return;
    if (watchValues.prefecture === "") {
      setValue("city", "");
      setValue("town", "");
      setCities([""]);
      setTowns([""]);
      return;
    }

    let isMounted = true;
    (async () => {
      const r = await GetCities(watchValues.prefecture);
      if (isMounted) {
        if (!r.success) {
          return setSeverError(r.error);
        }

        setCities(r.cities);
        if (selectedHowMaBuilding) {
          // マンション名候補から選択時
          setValue("city", convertAreaNameToCity(selectedHowMaBuilding.area.name, r.cities));
        }
      }
      return undefined;
    })();
    return () => {
      isMounted = false;
    };
  }, [watchValues.prefecture]);

  /** Load towns for each time city is selected */
  React.useEffect(() => {
    if (!watchValues.city) return undefined;
    if (watchValues.city === "") return undefined;
    let isMounted = true;

    (async () => {
      const r = await GetTowns(watchValues.prefecture, watchValues.city);
      if (isMounted) {
        if (!r.success) {
          return setSeverError(r.error);
        }
        setTowns(r.towns);
        if (selectedHowMaBuilding) {
          // マンション名候補から選択時
          setValue("town", convertAreaNameToTown(selectedHowMaBuilding.area.name, r.towns));
        }
      }

      return undefined;
    })();

    return () => {
      isMounted = false;
    };
  }, [watchValues.city]);

  /** Load stations for each time railway is selected */
  React.useEffect(() => {
    if (!watchValues.railway) return undefined;
    if (watchValues.railway === "") return undefined;
    let isMounted = true;

    (async () => {
      const r = await GetStations(watchValues.railway);
      if (isMounted) {
        if (!r.success) {
          return setSeverError(r.error);
        }
        setStations(r.stations);
        if (selectedHowMaBuilding) {
          // マンション名候補から選択時
          setValue("station", selectedHowMaBuilding.station?.name);
        }
      }
      return undefined;
    })();
    return () => {
      isMounted = false;
    };
  }, [watchValues.railway]);

  const fp = PropallyApiFactory();
  const handleOnSave = async () => {
    try {
      const data = await fp.v1PropertiesPropertyIdPatch(propertyHolder.property.id, propertyState, {
        withCredentials: true,
      });
      if (data.status === 200) {
        setManagementDetailSnackBarState({
          message: "物件詳細を更新しました。",
          status: "success",
          isOpen: true,
        });
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件詳細の更新に失敗しました。",
        status: "error",
        isOpen: true,
      });
    }
    if (propertyState.property_type === PROPERTY_TYPE_VALUES.consideration) {
      history.push("/management_consideration/detail");
    } else {
      history.push("/management/detail");
    }
  };

  const buildingsSearch = (searchBuildingName: string) => {
    setValue("name", searchBuildingName);
    // 入力し直しの場合は選択肢は空にする
    setBuildingOptions([]);
    setRoomOptions([]);

    if (searchBuildingName.length < 2) return;

    setIsBuildingsSearching(true);
    debounceBuildingsSearch(searchBuildingName);
  };

  const debounceBuildingsSearch = debounce(
    (searchBuildingName: string) => {
      fetchBuildingOptions(searchBuildingName);
    },
    1000 // 1秒
  );

  // 建物情報取得
  const fetchBuildingOptions = (searchBuildingName: string) => {
    fp.v1HowmaBuildingsPost({ text: searchBuildingName }, { withCredentials: true })
      .then((res) => {
        setBuildingOptions(res.data.buildings);
        setIsBuildingsSearching(false);
      })
      .catch(() => {
        setSeverError("物件検索に失敗しました。再度お試し頂くか、手動で入力してください。");
        setIsBuildingsSearching(false);
      });
  };
  // 部屋情報取得
  const fetchRoomOptions = (buildingId: number) => {
    fp.v1HowmaBuildingsBuildingIdGet(buildingId, { withCredentials: true })
      .then((res) => {
        setRoomOptions(res.data.rooms);
      })
      .catch(() => {
        setSeverError("物件検索に失敗しました。再度お試し頂くか、手動で入力してください。");
      });
  };

  // 築年の選択肢
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(90), (val, index) => currentYear - 70 + index); // Array of years from current year - 70 to current year + 20
  const builtAtYearOptions = years.map((year) => ({ value: year.toString(), label: year.toString() }));

  // 単発でかかる経費情報の編集リンクのクリック時
  const onClickEditProrpertyInfoExtra = async () => {
    await updatePropertyEditPageState("property_info_extra");
    history.push("/edit_property");
  };

  return (
    <BaseTemplate
      headerTitle="物件詳細"
      onBack={onClickPageBack}
      HeaderActionComponent={
        <Button color="primary" onClick={handleOnSave}>
          <Typography variant="h3">保存</Typography>
        </Button>
      }
    >
      <Box>
        <Box className={classes.content}>
          {isBuildingsSearching && (
            <Box>
              <Typography variant="subtitle2" color="inherit">
                マンション検索中...
                <CircularProgress style={{ marginLeft: "4px" }} size={14} />
              </Typography>
            </Box>
          )}
          {/* マンション・アパート名 */}
          <Controller
            name="name"
            control={control}
            rules={{ required: "入力してください" }}
            render={({ ...field }) => (
              <Autocomplete
                size="small"
                freeSolo
                disableClearable
                options={buildingOptions}
                // ref={field.ref}
                value={field.value}
                // MEMO: getOptionLabelのoptionにはoptionsでvalueを含めなくても直接入力されたstring型のvalueも含まれるためstring型の考慮をしている
                getOptionLabel={(option: HowMaBuilding | string) => {
                  return typeof option === "string" ? option : `${option.name}`;
                }}
                // MEMO: renderOptionのoptionには直接入力されたstring型のvalueは入ってこないためstring型の考慮不要
                renderOption={(option: HowMaBuilding) => (
                  <Box>
                    <Typography variant="body2" component="div">
                      {option.name}
                    </Typography>
                    <Typography variant="caption">({option.area.name})</Typography>
                  </Box>
                )}
                renderInput={(params) => {
                  return (
                    <CustomAutoCompleteForm
                      params={params}
                      label="マンション・アパート名"
                      helperText="マンション・アパート名から物件情報を自動入力する"
                      required
                      ref={field.ref}
                      error={!!errors.name}
                      errorText={errors.name?.message}
                    />
                  );
                }}
                // MEMO: オートコンプリートの選択肢の中から選択した場合の処理
                onChange={(e, selected: HowMaBuilding) => {
                  // 手動入力のEnter確定時にもonChangeが発火してしまい、その場合には文字列が入ってきてしまうため、
                  // 選択肢の中からの選択ではない文字列(=string)の場合は後続処理スキップ
                  if (typeof selected === "string") return;

                  // マンション情報自動入力
                  setSelectedHowMaBuilding(selected);

                  // 部屋詳細APIを実行して部屋情報取得
                  fetchRoomOptions(selected.id);
                }}
                // MEMO: 手動入力の場合の処理
                // onInputChangeのdataに渡される値は、オートコンプリート選択肢から選択された場合も、選択されたoptionにあたるgetOptionLabelの値が利用されるため、Building型は入らずstring型のみ
                onInputChange={(e: React.ChangeEvent, inputed: string, reason: AutocompleteInputChangeReason) => {
                  // 候補選択時でもinputが発火してしまうため、手動入力以外は検索処理を実行させない
                  if (reason !== "input") return;
                  // 検索実行
                  buildingsSearch(inputed);
                }}
                // MEMO: inputエリアフォーカス時に実行したい処理
                onFocus={() => {}}
              />
            )}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/* 号室 */}
              <Controller
                control={control}
                name="room_number"
                rules={{ required: "入力してください" }}
                render={({ ...field }) => (
                  <Autocomplete
                    id="room_number"
                    size="small"
                    freeSolo
                    disableClearable
                    value={field.value}
                    options={roomOptions}
                    // MEMO: getOptionLabelのoptionにはoptionsでvalueを含めなくても直接入力されたstring型のvalueも含まれるためstring型の考慮をしている
                    getOptionLabel={(option: HowMaRoom | string) => {
                      return typeof option === "string" ? option : `${option.name}`;
                    }}
                    // MEMO: renderOptionのoptionには直接入力されたstring型のvalueは入ってこないためstring型の考慮不要
                    renderOption={(option: HowMaRoom) => (
                      <Box>
                        <Typography variant="body2" component="div">
                          {option.name}
                          <Typography variant="caption">号室</Typography>
                        </Typography>
                      </Box>
                    )}
                    renderInput={(params) => {
                      return (
                        <CustomAutoCompleteForm
                          params={params}
                          label="号室"
                          required
                          ref={field.ref}
                          error={!!errors.room_number}
                          errorText={errors.room_number?.message}
                        />
                      );
                    }}
                    // 選択肢選択時
                    onChange={(e, selected: HowMaRoom) => {
                      // 自動入力
                      setSelectedHowMaRoom(selected);
                    }}
                    // 手動入力時
                    onInputChange={(e: React.ChangeEvent, inputed: string) => {
                      setValue("room_number", inputed);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {/* 所在階 */}
              <Controller
                name="floor"
                control={control}
                rules={{ required: "入力してください" }}
                render={({ ...field }) => (
                  <>
                    <CustomForm
                      {...field}
                      label="所在階"
                      error={!!errors.floor}
                      errorText={errors.floor?.message}
                      onChange={field.onChange}
                      placeHolder="1"
                      unitSuffix="階"
                    />
                    <Typography variant="caption">※一棟の場合は「0」と入力</Typography>
                  </>
                )}
              />
            </Grid>
          </Grid>

          {/* 都道府県 */}
          <Controller
            control={control}
            name="prefecture"
            rules={{ required: "入力してください" }}
            render={({ ...field }) => (
              <Autocomplete
                id="prefecture"
                options={!field.value || field.value === "" ? [field.value, ...prefectures] : prefectures}
                value={field.value}
                size="small"
                filterOptions={(options, state) => {
                  const defaultFiltered = defaultFilterOptions(options, state);
                  return defaultFiltered.filter((option) => option !== "" && option !== null);
                }}
                getOptionLabel={(option) => {
                  return !option ? "" : option;
                }}
                renderOption={(params) => <>{params}</>}
                renderInput={(params) => {
                  return (
                    <CustomAutoCompleteForm
                      params={params}
                      label="都道府県"
                      required
                      ref={field.ref}
                      error={!!errors.prefecture}
                      errorText={errors.prefecture?.message}
                    />
                  );
                }}
                onChange={(e, data) => {
                  field.onChange(data);
                  setValue("city", "");
                  setValue("town", "");
                }}
              />
            )}
          />

          {/* 市区町村 */}
          <Controller
            name="city"
            control={control}
            rules={{ required: "入力してください" }}
            render={({ ...field }) => (
              <Autocomplete
                id="city"
                options={!field.value || field.value === "" ? [field.value, ...cities] : cities}
                value={field.value}
                size="small"
                filterOptions={(options, state) => {
                  const defaultFiltered = defaultFilterOptions(options, state);
                  return defaultFiltered.filter((option) => option !== "" && option !== null);
                }}
                getOptionLabel={(option) => {
                  return !option ? "" : option;
                }}
                getOptionSelected={(option, value) => option === value}
                renderOption={(params) => <>{params}</>}
                renderInput={(params) => {
                  return (
                    <CustomAutoCompleteForm
                      params={params}
                      label="市区町村"
                      required
                      ref={field.ref}
                      error={!!errors.city}
                      errorText={errors.city?.message}
                    />
                  );
                }}
                onChange={(e, data) => {
                  field.onChange(data);
                  setValue("town", "");
                }}
                disabled={!watchValues.prefecture || watchValues.prefecture === ""}
              />
            )}
          />

          {/* 番地 */}
          {towns.length > 1 && (
            <Controller
              control={control}
              name="town"
              error={!!errors.town}
              helperText={errors.town?.message}
              render={({ ...field }) => (
                <Autocomplete
                  id="town"
                  // options={!field.value || field.value === "" ? [field.value, ...towns] : towns}
                  options={!field.value || field.value === "" ? [field.value, ...towns] : towns}
                  value={field.value}
                  size="small"
                  filterOptions={(options, state) => {
                    const defaultFiltered = defaultFilterOptions(options, state);
                    return defaultFiltered.filter((option) => option !== "" && option !== null);
                  }}
                  getOptionLabel={(option) => {
                    return !option ? "" : option;
                  }}
                  renderOption={(params) => <>{params}</>}
                  renderInput={(params) => {
                    return (
                      <CustomAutoCompleteForm
                        params={params}
                        label="番地"
                        required
                        ref={field.ref}
                        error={!!errors.town}
                        errorText={errors.town?.message}
                      />
                    );
                  }}
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  disabled={!watchValues.city || watchValues.city === ""}
                />
              )}
            />
          )}
          {/* 路線名 */}
          <Controller
            control={control}
            name="railway"
            rules={{ required: "入力してください" }}
            render={({ ...field }) => (
              <Autocomplete
                id="railway"
                options={
                  !field.value || field.value === ""
                    ? [field.value, ...railways.map((v) => v.name)]
                    : railways.map((v) => v.name)
                }
                value={field.value}
                size="small"
                filterOptions={(options, state) => {
                  const defaultFiltered = defaultFilterOptions(options, state);
                  return defaultFiltered.filter((option) => option !== "" && option !== null);
                }}
                getOptionLabel={(option) => {
                  return !option ? "" : option;
                }}
                renderOption={(params) => <>{params}</>}
                renderInput={(params) => {
                  return (
                    <CustomAutoCompleteForm
                      params={params}
                      label="路線名"
                      required
                      ref={field.ref}
                      error={!!errors.railway}
                      errorText={errors.railway?.message}
                    />
                  );
                }}
                onChange={(e, data) => {
                  setValue("station", "");
                  field.onChange(data);
                }}
              />
            )}
          />

          {/* 駅名 */}
          {stations.length > 1 && (
            <Controller
              control={control}
              name="station"
              rules={{ required: "入力してください" }}
              render={({ ...field }) => (
                <Autocomplete
                  id="station"
                  options={!field.value || field.value === "" ? [field.value, ...stations] : stations}
                  value={field.value}
                  size="small"
                  filterOptions={(options, state) => {
                    const defaultFiltered = defaultFilterOptions(options, state);
                    return defaultFiltered.filter((option) => option !== "" && option !== null);
                  }}
                  getOptionLabel={(option) => {
                    return !option ? "" : option;
                  }}
                  renderOption={(params) => <>{params}</>}
                  renderInput={(params) => {
                    return (
                      <CustomAutoCompleteForm
                        params={params}
                        label="駅名"
                        required
                        ref={field.ref}
                        error={!!errors.railway}
                        errorText={errors.railway?.message}
                      />
                    );
                  }}
                  onChange={(e, data) => field.onChange(data)}
                  disabled={watchValues.railway === "" || !watchValues.railway}
                />
              )}
            />
          )}
          {/* 駅徒歩 */}
          <Controller
            name="walk"
            control={control}
            rules={{ required: "入力してください。" }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="駅徒歩"
                required
                error={!!errors.walk}
                errorText={errors.walk?.message}
                placeHolder="10"
                unitSuffix="分"
                isShort
              />
            )}
          />

          {/* 築年 */}
          <Controller
            name="built_at"
            control={control}
            rules={{
              required: "築年を選択してください。",
            }}
            render={({ ...field }) => (
              <CustomSelector
                {...field}
                label="築年"
                required
                errorText={errors.built_at?.message}
                onChange={field.onChange}
                options={builtAtYearOptions}
                placeHolder={`${currentYear}`}
                suffix="年"
              />
            )}
          />
          {/* 専有面積 */}
          <Controller
            name="occupied_area_m2"
            control={control}
            rules={{ required: "専有面積を入力してください。" }}
            render={({ ...field }) => (
              <>
                <CustomForm
                  {...field}
                  label="専有面積"
                  required
                  error={!!errors.occupied_area_m2}
                  errorText={errors.occupied_area_m2?.message}
                  onChange={field.onChange}
                  placeHolder="32"
                  unitSuffix="m²"
                  isShort
                />
                <Typography variant="caption">※一棟の場合は「0」と入力</Typography>
              </>
            )}
          />
          {/* 間取り */}
          <Controller
            name="layout"
            control={control}
            rules={{
              required: "間取りを選択してください。",
            }}
            render={({ ...field }) => (
              <CustomSelector
                {...field}
                label="間取り"
                onChange={field.onChange}
                error={!!errors.layout}
                errorText={errors.layout?.message}
                options={Layout.map((layout) => ({ label: layout, value: layout }))}
                placeHolder="未選択"
              />
            )}
          />
          {/* 管理形態 */}
          <Controller
            name="management_type"
            control={control}
            rules={{
              required: "管理形態を選択してください。",
            }}
            render={({ ...field }) => (
              <CustomSelector
                {...field}
                label="管理形態"
                required
                onChange={field.onChange}
                error={!!errors.management_type}
                errorText={errors.management_type?.message}
                options={NEW_MANAGEMENT_TYPE_KEY_VALUES.map((managementType) => {
                  return { value: managementType.value, label: managementType.value };
                })}
              />
            )}
          />
          {/* 売却状況 */}
          <Controller
            name="sales_status"
            control={control}
            rules={{
              required: "売却状況を選択してください。",
            }}
            render={({ ...field }) => (
              <CustomSelector
                {...field}
                label="売却状況"
                required
                onChange={field.onChange}
                error={!!errors.sales_status}
                errorText={errors.sales_status?.message}
                options={Object.values(PropertySalesStatusEnum).map((salesStatus) => {
                  return { value: salesStatus, label: salesStatus };
                })}
              />
            )}
          />

          {/* 希望売却価格 ※保有物件でのみ表示する*/}
          {/* TODO 保有物件でのみ表示する処理が未実装 */}
          {true && (
            <Controller
              name="asking_price"
              control={control}
              rules={{ required: "入力してください。" }}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="希望売却価格"
                  onChange={field.onChange}
                  error={!!errors.asking_price}
                  errorText={errors.asking_price?.message}
                  placeHolder="100,000,000"
                  unitSuffix="円"
                  formatNumeric
                />
              )}
            />
          )}

          {/* 編集画面へのリンクを追加 */}
          <Box className={classes.historyLink}>
            <Typography variant="button" color="primary" onClick={onClickEditProrpertyInfoExtra}>
              単発でかかる経費情報の編集
            </Typography>
          </Box>
          {/* 固定資産税・都市計画税 (年) */}
          {/* 設備修繕、大規模修繕費用 */}
          {/* その他一過性の支出 */}
        </Box>
      </Box>
    </BaseTemplate>
  );
};
