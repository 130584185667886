import { List, ListItem, ListItemText, makeStyles, Tabs, Tab, Theme, Box } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilStateLoadable } from "recoil";

import { IdName, User } from "../../../api/generated";
import { useGetMasterResidenceTypes, useGetMasterTags } from "../../../hooks/useGetMasterData";
import { NavigationPath } from "../../../scripts/components/renewal_v1/enums";
import { BaseTemplate } from "../../../scripts/components/renewal_v1/template/BaseTemplate";
import { useDevice } from "../../../utilities/sp/use_device";
import { UserState } from "../../../view_models/atoms";
import { Loading } from "../../loading";

import { BasicInfo } from "./items/BasicInfo";
import { DeleteAccount } from "./items/DeleteAccount";
import { LogoutTab } from "./items/Logout";
import { ReportTab } from "./items/Report";
import { ResetPassword } from "./items/ResetPassword";
import { UpdateEmail } from "./items/UpdateEmail";



const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: ({ isMobile }: { isMobile: boolean }) => (isMobile ? "100%" : "80%"),
    padding: `${theme.spacing(3)}`,
  },
  item: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    background: `${theme.palette.grey[50]}`,
  },
  text: {
    fontSize: "12px",
  },
  icon: {
    fontSize: "12px",
  },
  tab: {
    minWidth: "unset",
  },
}));

export interface SettingItemsProps {
  user?: User;
  setUser?: (user: User) => void;
  masterResidenceTypes?: IdName[];
  masterTags?: IdName[];
}

const SETTING_CATEGORIES = [
  {
    id: "general",
    title: "一般設定",
    items: [
      { id: "basic", label: "基本情報の編集", component: BasicInfo },
      { id: "email", label: "メールアドレスの変更", component: UpdateEmail },
      { id: "password", label: "パスワードの変更", component: ResetPassword },
      { id: "report", label: "不具合報告・改善要望", component: ReportTab },
    ],
  },
  {
    id: "logout",
    items: [{ id: "logout", label: "ログアウト", component: LogoutTab }],
  },
  {
    id: "account",
    items: [{ id: "delete", label: "退会", component: DeleteAccount }],
  },
];

const SETTING_ITEMS = SETTING_CATEGORIES.flatMap((category) => category.items);

const SettingsPage: React.FC = () => {
  const { isMobile } = useDevice();
  const history = useHistory();
  const classes = useStyles({ isMobile });
  const [currentSetting, setCurrentSetting] = React.useState("menu");

  const [loadable, setUser] = useRecoilStateLoadable(UserState);

  const { masterResidenceTypes, loading: masterResidenceTypesLoading } = useGetMasterResidenceTypes();
  const { masterTags, loading: masterTagsLoading } = useGetMasterTags();

  const isMasterDataLoading = masterResidenceTypesLoading || masterTagsLoading;

  const handleSetUser = (user: User) => {
    if (loadable.state === "hasValue" && loadable.contents) {
      setUser({
        ...loadable.contents,
        ...user,
      });
    }
  };

  const getHeaderTitle = () => {
    if (currentSetting === "menu") return "設定";
    const selectedSetting = SETTING_ITEMS.find((item) => item.id === currentSetting);
    return selectedSetting ? selectedSetting.label : "設定";
  };

  const handleBack = () => {
    if (currentSetting === "menu") {
      history.goBack();
      return;
    }
    setCurrentSetting("menu");
  };

  const renderCurrentSettingContent = () => {
    if (loadable.state === "hasError") {
      throw loadable.contents;
    }

    if (loadable.state === "loading" || loadable.contents == null || isMasterDataLoading) {
      return <Loading />;
    }

    const user = loadable.contents;

    // PCの場合は常に基本情報を表示（currentSettingが"menu"の場合も）
    if (!isMobile && currentSetting === "menu") {
      const basicSetting = SETTING_ITEMS.find((item) => item.id === "basic");
      if (basicSetting) {
        const BasicComponent = basicSetting.component;
        return (
          <BasicComponent
            user={user}
            setUser={handleSetUser}
            masterResidenceTypes={masterResidenceTypes}
            masterTags={masterTags}
          />
        );
      }
    }

    const selectedSetting = SETTING_ITEMS.find((item) => item.id === currentSetting);
    if (!selectedSetting) return null;

    const SettingComponent = selectedSetting.component;
    return (
      <SettingComponent
        user={user}
        setUser={handleSetUser}
        masterResidenceTypes={masterResidenceTypes}
        masterTags={masterTags}
      />
    );
  };

  return (
    <BaseTemplate headerTitle={getHeaderTitle()} navigationPath={NavigationPath.SETTINGS} onBack={handleBack} isDense>
      {(currentSetting === "menu" || !isMobile) &&
        (isMobile ? (
          <>
            {SETTING_CATEGORIES.map((category) => (
              <List key={category.id}>
                {category.items.map((item) => (
                  <ListItem key={item.id} button onClick={() => setCurrentSetting(item.id)} className={classes.item}>
                    <ListItemText primaryTypographyProps={{ variant: "body2" }} primary={item.label} />
                    <ArrowForwardIos className={classes.icon} />
                  </ListItem>
                ))}
              </List>
            ))}
          </>
        ) : (
          <Tabs
            value={currentSetting === "menu" ? "basic" : currentSetting}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, newValue) => setCurrentSetting(newValue)}
            centered
          >
            {SETTING_ITEMS.map((item) => (
              <Tab key={item.id} value={item.id} label={item.label} className={classes.tab} />
            ))}
          </Tabs>
        ))}

      {(currentSetting !== "menu" || !isMobile) && (
        <Box display="flex" justifyContent="center">
          <Box className={classes.content}>{renderCurrentSettingContent()}</Box>
        </Box>
      )}
    </BaseTemplate>
  );
};

export default SettingsPage;
