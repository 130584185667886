import { Typography, Box, makeStyles, TextField } from "@material-ui/core";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import * as React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";

import { UserRegistrations } from "../../../api/generated";
import { PropallyApiFactory } from "../../../module/custom_api_factory";
import { EntranceTemplate } from "../../../scripts/components/renewal_v1/template/entrance_template";
import { TextLink } from "../../../scripts/components/renewal_v1/text_link";
import { AppEventTokenEnum, sendEventToAppMeasurementTool } from "../../../utilities/capacitor_logic";
import { SessionState, UserRegistrationsFormState } from "../../../view_models/atoms";
import { Loading } from "../../loading";

import { PostRegisterRequestBody } from "./types";

enum SmsVerificationStatus {
  INITIAL = 0, // 初期状態
  VERIFYING = 1, // 認証中
  VERIFIED = 2, // 認証成功
}

const useStyles = makeStyles((theme) => {
  const inputSpaceInBetween = 16; // フォームフィールド間のスペース (px)
  const digitNumber = 6; // 文字数
  const containerWidth = 330; // コンテナの横幅 (px)
  const inputEachWidth = (containerWidth - inputSpaceInBetween * (digitNumber - 1)) / digitNumber; // 各入力フィールドの幅

  return {
    input: {
      width: `${inputEachWidth}px`,
      height: "45px",
      [theme.breakpoints.down("sm")]: {
        height: "48px",
      },
      textAlign: "center",
      fontSize: "2rem",
      "& input": {
        textAlign: "center",
        padding: "0", // デフォルトのパディングを削除
        height: "48px", // 高さを設定
        lineHeight: "48px", // 高さに合わせて行の高さを設定
        fontSize: theme.typography.h1.fontSize, // フォントサイズを設定
        "@media (max-width:600px)": {
          fontSize: "24px",
        },
      },
    },
    inputWrapper: {
      display: "flex",
      "& > *:not(:first-child)": {
        marginLeft: `${inputSpaceInBetween}px`, // 左側のマージンを設定
      },
    },
    boxContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%", // 幅を100%に設定
    },
    errorMessage: {
      color: "#DE351F", // TODO: 反映されないため。theme.palette.error.main,
      marginTop: theme.spacing(1),
      fontSize: theme.typography.caption.fontSize,
    },
    // コードブロック以外
    gridItem: {
      padding: theme.spacing(1),
    },
    backLink: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        borderBottom: "none",
      },
    },
  };
});

interface RegisterSmsVerificationCodePageProps {
  onBack: () => void;
}

// APIの呼び出し
const postRegistrations = async (data: PostRegisterRequestBody) => {
  const fp = PropallyApiFactory();
  try {
    await fp.v1RegistrationsPost(data, { withCredentials: true });
    return { success: true, error: "" };
  } catch (e) {
    if (e.response?.status === 422) {
      switch (e.response?.data.code) {
        case "REGISTERED":
          return {
            success: false,
            error: "入力されたメールアドレスは既に登録済みです",
          };
        case "INVALID_PHONE_NUMBER_AUTH_CODE":
          return {
            success: false,
            error: "電話番号認証コードが不正です",
          };
        default:
          return {
            success: false,
            error: "入力内容が不正です",
          };
      }
    }
    return {
      success: false,
      error: "ネットワークエラーです。しばらくたってからお試しください",
    };
  }
};

const postLogin = async (data: { email: string; password: string }) => {
  const fp = PropallyApiFactory();
  try {
    await fp.v1LoginPost(data, { withCredentials: true });
    return { success: true, error: "" };
  } catch (e) {
    if (e.response?.status === 401) {
      return {
        success: false,
        error: "メールアドレスまたはパスワードが不正です",
      };
    }
    return {
      success: false,
      error: "ネットワークエラーです。しばらくたってからお試しください",
    };
  }
};

const RegisterSmsVerificationCodePage: React.FC<RegisterSmsVerificationCodePageProps> = ({ onBack }) => {
  const classes = useStyles();
  // SMS認証の状態
  const [isSmsVerified, setIsSmsVerified] = React.useState<SmsVerificationStatus>(SmsVerificationStatus.INITIAL);

  const [error, setError] = React.useState<string>("");
  const { control, handleSubmit, setValue, getValues, trigger } = useFormContext();
  const inputsRef = React.useRef([]);
  const [formData, setFormData] = useRecoilState(UserRegistrationsFormState);
  const history = useHistory();
  const setSession = useSetRecoilState(SessionState);

  // UIのレンダリング処理
  const renderContent = () => {
    switch (isSmsVerified) {
      case SmsVerificationStatus.VERIFIED:
        return <CheckCircleOutlineSharpIcon style={{ color: "white", fontSize: "3rem" }} />;
      case SmsVerificationStatus.VERIFYING:
        return <Loading />;
      default:
        return null;
    }
  };

  const onSubmit = React.useCallback(
    async (data: UserRegistrations) => {
      const validate_result = await trigger();
      if (!validate_result) return;

      // 認証中画面の表示
      setIsSmsVerified(SmsVerificationStatus.VERIFYING);

      const sendValues: UserRegistrations = {
        ...data,
        // 「不動産会社からのオファー希望」は現状、非表示項目となるので、true(希望する)で固定値を送信する
        request_the_proposal: true,
      };

      const result = await postRegistrations(sendValues);
      if (result.success) {
        sendEventToAppMeasurementTool(AppEventTokenEnum.UserRegistrationCompleted);
        // 会員登録完了したら、ログインを行う
        const loginResult = await postLogin({ email: data.email, password: data.password });
        setSession({
          logged_in: loginResult.success,
        });

        // sendEventToAppMeasurementTool(AppEventTokenEnum.UserRegistrationCompleted);

        // データ送信後にRecoilの状態をクリア
        setFormData(null);
        // history.push("/registrations/complete");
        //認証成功画面の表示
        setIsSmsVerified(SmsVerificationStatus.VERIFIED);
        // 認証成功後、3秒後にアカウント情報画面に遷移
        setTimeout(() => {
          history.push("/first_account");
        }, 3000);
      } else {
        setError(result.error);
        setIsSmsVerified(SmsVerificationStatus.INITIAL);
      }
    },
    [trigger, setFormData]
  );

  const handleChange = React.useCallback(
    (e, index) => {
      const { value } = e.target;
      if (value.match(/^[0-9]$/)) {
        setValue(`code${index}`, value);
        if (index < 5) {
          inputsRef.current[index + 1].focus();
        } else {
          const updatedFormData = {
            ...formData,
            phone_number_auth_code: parseInt(Object.values(getValues()).join("")),
          };
          handleSubmit(() => onSubmit(updatedFormData))();
        }
      } else if (value === "") {
        setValue(`code${index}`, "");
      }
    },
    [getValues, setValue, formData, handleSubmit, onSubmit]
  );

  const handleKeyDown = React.useCallback(
    (e, index) => {
      if (e.key === "Backspace" && !getValues(`code${index}`) && index > 0) {
        inputsRef.current[index - 1].focus();
      }
    },
    [getValues]
  );

  // クリップボードからの読み込み機能
  const handlePaste = async (e) => {
    try {
      const text = await navigator.clipboard.readText();
      const textArray = text.split("").slice(0, 6);
      textArray.forEach((value, index) => {
        setValue(`code${index}`, value);
        if (inputsRef.current[index]) {
          inputsRef.current[index].value = value;
        }
      });
      if (textArray.length >= 6) {
        const updatedFormData = { ...formData, phone_number_auth_code: parseInt(Object.values(getValues()).join("")) };
        handleSubmit(() => onSubmit(updatedFormData))();
      }
      e.preventDefault();
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
    }
  };

  return (
    <EntranceTemplate title="SMS認証" subTitle="認証コードを送信してください。">
      {error && (
        <Typography variant="caption" className={classes.errorMessage}>
          {error}
        </Typography>
      )}
      <Box mt={4}>
        <Box className={classes.boxContainer}>
          <Box className={classes.inputWrapper}>
            {Array.from({ length: 6 }).map((_, index) => (
              <Controller
                key={index}
                name={`code${index}`}
                control={control}
                defaultValue=""
                render={({ ...field }) => (
                  <TextField
                    inputRef={(el) => (inputsRef.current[index] = el)}
                    {...field}
                    onChange={(e) => handleChange(e, index)}
                    type="text" // "number"はiOS端末での入力時に挙動バグるため利用しない
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={(e) => handlePaste(e)}
                    variant="outlined"
                    className={classes.input}
                    inputProps={{
                      maxLength: 1,
                      pattern: "[0-9]*",
                    }}
                  />
                )}
              />
            ))}
          </Box>
        </Box>
        {error && (
          <Typography className={classes.errorMessage}>
            ※認証番号が間違っています。有効期限が過ぎている場合は、新しい認証番号を送信してください。
          </Typography>
        )}
        <Box display="flex" margin="40px auto" justifyContent="center" width="300px">
          <TextLink href="" underline onClick={onBack}>
            <Typography variant="body1">認証コードを再送する</Typography>
          </TextLink>
        </Box>
        <Box display="flex" margin="32px auto" justifyContent="center">
          <Typography variant="button" className={classes.backLink} onClick={onBack}>
            戻る
          </Typography>
        </Box>
      </Box>
      {isSmsVerified !== SmsVerificationStatus.INITIAL && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor="rgba(0, 0, 0, 0.5)"
        >
          {renderContent()}
        </Box>
      )}
    </EntranceTemplate>
  );
};

export default RegisterSmsVerificationCodePage;
