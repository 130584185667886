import { useSnackbar } from "notistack";

import { PropallyApiFactory } from "../module/custom_api_factory";

export const useSendPhoneNumberAuthCode = () => {
  const { enqueueSnackbar } = useSnackbar();

  const fp = PropallyApiFactory();

  const sendPhoneNumberAuthCode = async (phone_number: string): Promise<void> => {
    try {
      const sendResponse = await fp.v1SendPhoneNumberAuthCodesPost(
        { phone_number: phone_number },
        {
          withCredentials: true,
        }
      );
      if (sendResponse.status === 200) {
        enqueueSnackbar("電話番号認証コード送信に成功しました。");
      }
    } catch (e) {
      enqueueSnackbar("電話番号認証コード送信に失敗しました。");
    }
  };

  return { sendPhoneNumberAuthCode };
};
