import { makeStyles, Box, Typography } from "@material-ui/core";
import * as React from "react";
import { useRecoilValue } from "recoil";

import { CustomButton } from "../../../../../../scripts/components/renewal_v1/button";
import { BaseTemplate } from "../../../../../../scripts/components/renewal_v1/template/BaseTemplate";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { LeaseContractHistoryEditor } from "./history_edit";
import { LeaseContractHistoryItem } from "./history_item";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "16px auto 0px auto",
  },

  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: 16,
    marginBottom: 8,
  },
  iconRoot: {
    padding: 0,
  },
  textLink: {
    width: 80,
    textAlign: "center",
  },
}));

interface props {
  onClickPageBack: () => void;
}

export const EditPropertySectionLeaseContractHistory: React.FC<props> = ({ onClickPageBack }) => {
  const classes = useStyles();
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);
  const [isShowNewHistory, setIsShowNewHistory] = React.useState(false);

  const handleAddNewHistory = () => {
    setIsShowNewHistory(true);
  };

  const handleOnCreate = () => {
    setIsShowNewHistory(false);
  };

  return (
    <BaseTemplate headerTitle="過去履歴" onBack={onClickPageBack}>
      <Box>
        <Box className={classes.content}>
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant="h2">賃貸契約情報</Typography>
            </Box>
            {selected_property?.lease_contracts.map((result, index) => {
              return <LeaseContractHistoryItem key={index} leaseContract={result} />;
            })}
            {isShowNewHistory && (
              <Box className={classes.background}>
                <LeaseContractHistoryEditor onCancel={() => setIsShowNewHistory(false)} onCreate={handleOnCreate} />
              </Box>
            )}
          </Box>
        </Box>
        <CustomButton type="button" customVariant="outlinedPrimary" onClick={handleAddNewHistory}>
          新規追加
        </CustomButton>
      </Box>
    </BaseTemplate>
  );
};
