import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { SettingItemsProps } from "../SettingsPage";

export const ResetPassword: React.FC<SettingItemsProps> = () => {
  return (
    <>
      <Box mb={4} display="flex" flexDirection="column" textAlign="center" style={{ gap: "12px" }}>
        <Typography variant="h3">パスワードリセット</Typography>
        <Typography>
          パスワードの変更をご希望の場合は、
          <br />
          以下からパスワードをリセットして <br />
          再設定してください。
        </Typography>
      </Box>
      <Box justifyContent="center" display="flex">
        <CustomButton customVariant="filled" href="password_reset/new">
          パスワードリセットへ
        </CustomButton>
      </Box>
    </>
  );
};
