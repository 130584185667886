import { JOB_INDUSTRIES, LOAN_RANGES, OCCUPATIONS, PERIOD_RANGES, PREFECTURES } from "../../api/options/account";

// カスタムセレクトボックスのoprtionsのためにvalue-labelの形式に変換する
export const createOptionsFromArray = <T>(array: T[]): { value: T; label: string }[] => {
  return array.map((item) => ({ value: item, label: item.toString() }));
};

export const getYearOptions = (): {
  value: number;
  label: string;
}[] => {
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(60), (val, index) => currentYear - index);
  return years.map((year) => ({ value: year, label: year.toString() }));
};

export const getMonthOptions = (): {
  value: number;
  label: string;
}[] => {
  const months = Array.from(new Array(12), (val, index) => index + 1);
  return months.map((month) => ({ value: month, label: month.toString() }));
};

export const YEAR_OPTIONS = getYearOptions();
export const MONTH_OPTIONS = getMonthOptions();

export const PREFECTURE_OPTIONS = createOptionsFromArray(PREFECTURES);
export const OCCUPATION_OPTIONS = createOptionsFromArray(OCCUPATIONS);
export const JOB_CATEGORY_OPTIONS = createOptionsFromArray(JOB_INDUSTRIES);
export const LOAN_RANGE_OPTIONS = createOptionsFromArray(LOAN_RANGES);
export const PERIOD_RANGE_OPTIONS = createOptionsFromArray(PERIOD_RANGES);
