import Button, { ButtonProps } from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    textTransform: "none",
    padding: theme.spacing(2),
    display: "flex",
    margin: "0 auto",
    maxWidth: 300,
    height: 53,
  },
  filled: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      borderColor: theme.palette.text.disabled,
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.background.paper,
    },
  },
  outlinedPrimary: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      borderColor: theme.palette.text.disabled,
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.background.paper,
    },
  },
  outlinedGray: {
    border: `2px solid ${theme.palette.grey[900]}`,
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:disabled": {
      borderColor: theme.palette.text.disabled,
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.background.paper,
    },
  },
  textDisabled: {
    opacity: 0.3,
  },
}));

// CustomButtonコンポーネント
interface CustomButtonProps extends ButtonProps {
  customVariant: "filled" | "outlinedPrimary" | "outlinedGray";
  children: React.ReactNode;
  isEdit?: boolean;
}
export const CustomButton: React.FC<CustomButtonProps> = ({ customVariant, children, isEdit, ...props }) => {
  const classes = useStyles();
  const classNames = `${classes.root} ${classes[customVariant]}`;

  return (
    <Button fullWidth className={classNames} {...props} startIcon={isEdit && <CreateIcon />}>
      {children}
    </Button>
  );
};

const ButtonGroup: React.FC = () => {
  return (
    <Grid container spacing={2} direction="column">
      <Grid item container spacing={2}>
        <Grid item>
          <CustomButton customVariant="filled">新規会員登録</CustomButton>
        </Grid>
        <Grid item>
          <CustomButton customVariant="outlinedPrimary">新規会員登録</CustomButton>
        </Grid>
        <Grid item>
          <CustomButton customVariant="outlinedGray">新規会員登録</CustomButton>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <CustomButton customVariant="filled">ラベル</CustomButton>
        </Grid>
        <Grid item>
          <CustomButton customVariant="outlinedPrimary">ラベル</CustomButton>
        </Grid>
        <Grid item>
          <CustomButton customVariant="outlinedGray">ラベル</CustomButton>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <CustomButton customVariant="filled" disabled>
            Filled/Disabled
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton customVariant="outlinedPrimary" disabled>
            OutlinedPrimary/Disabled
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton customVariant="outlinedGray" disabled>
            OutlinedGray/Disabled
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ButtonGroup;
