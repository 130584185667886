import { useEffect, useState } from "react";

import { ProposalMatching } from "../api/generated";
import { PropallyApiFactory } from "../module/custom_api_factory";

interface UseGetProposalMatchings {
  proposalMatchings: ProposalMatching[];
  loading: boolean;
}

export const useGetProposalMatchings = (): UseGetProposalMatchings => {
  const [proposalMatchings, setProposalMatchings] = useState<ProposalMatching[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchProposalMatchings = async () => {
    try {
      setLoading(true);

      const fp = PropallyApiFactory();
      const response = await fp.v1ProposalMatchingsGet({
        withCredentials: true,
      });
      setProposalMatchings(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProposalMatchings();
  }, []);

  return { proposalMatchings, loading };
};
