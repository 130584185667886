import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";

import LogoImage from "../../../../assets/image/logo_gradation01.png";
import ShakehandImage from "../../../../assets/image/shakehands.png";
import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { AccountIllustrationTemplate } from "../../../scripts/components/renewal_v1/template/account_illustration_template";
import theme from "../../../scripts/components/renewal_v1/theme";

const useStyles = makeStyles(() => ({
  welcomeText: {
    marginBottom: 8,
  },
  logoImage: {
    width: "180px",
    height: "auto",
  },
  shakehandImage: {
    maxWidth: "100%",
    width: "390px",
    height: "auto",
    marginBottom: 32,
  },
}));

interface WelcomePageProps {
  onNext: () => void;
}

const WelcomePage: FC<WelcomePageProps> = ({ onNext }) => {
  const classes = useStyles();
  const handleNext = async () => {
    onNext();
  };

  return (
    <AccountIllustrationTemplate>
      <Box>
        <Typography variant="h4" className={classes.welcomeText}>
          ようこそ
        </Typography>
        <img src={LogoImage} alt="propally" className={classes.logoImage} />
        <img src={ShakehandImage} alt="Welcome" className={classes.shakehandImage} />
        <Box pb={10}>
          <Typography variant="h2" style={{ color: theme.palette.text.primary }}>
            最適な情報を提供するため、
            <br />
            あなたについて教えて下さい
          </Typography>
        </Box>
        <CustomButton customVariant="filled" onClick={handleNext}>
          次へ
        </CustomButton>
      </Box>
    </AccountIllustrationTemplate>
  );
};

export default WelcomePage;
