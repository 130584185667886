import { makeStyles, Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { MonthlyCashflowResult } from "../../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { CustomForm } from "../../../../../../scripts/components/renewal_v1/form";
import { parseFormattedNumber } from "../../../../../../utilities/renewal_v1/format";
import { PropertyManagementDetailSnackbarState, useRequestInitialLoading } from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { costType } from "./history";

const useStyles = makeStyles({
  textLink: {
    width: 80,
    textAlign: "center",
  },
});

// 履歴編集用コンポーネント
export const ManagementCostHistoryEditor: React.FC<{
  monthlyCashflowResult?: MonthlyCashflowResult;
  costType: costType;
  onCancel: () => void;
  onSave?: () => void;
  onCreate?: () => void;
}> = ({ monthlyCashflowResult, costType, onCancel, onSave, onCreate }) => {
  const classes = useStyles();
  const history = useHistory();
  const { control, errors, getValues } = useForm<any>();
  const requestInitialLoading = useRequestInitialLoading();
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);

  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);

  const fp = PropallyApiFactory();

  const onSubmit = async () => {
    const values = getValues();
    const sendData = {
      ...values,
      ...(values.building_management_cost && {
        building_management_cost: parseFormattedNumber(values.building_management_cost),
      }),
      ...(values.management_cost && {
        management_cost: parseFormattedNumber(values.management_cost),
      }),
      ...(values.repair_fund_cost && {
        repair_fund_cost: parseFormattedNumber(values.repair_fund_cost),
      }),
      ...(values.misc_cost && {
        misc_cost: parseFormattedNumber(values.misc_cost),
      }),
      month: `${values.month}-01`,
    };

    try {
      const result = await fp.v1PropertiesPropertyIdMonthlyCashflowResultsPatch(selected_property.id, sendData, {
        withCredentials: true,
      });
      if (result.status === 200) {
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
      history.push("/management/detail");
    }
  };

  const handleSave = async () => {
    console.log("in");
    if (onSave) {
      // エラーの場合エディターの非表示後、詳細ページに戻るためこの順序
      onSave();
      await onSubmit();
    } else if (onCreate) {
      await onSubmit();
      onCreate();
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <Controller
          name="month"
          control={control}
          rules={{ required: "入力してください。" }}
          defaultValue={dayjs(monthlyCashflowResult?.month).format("YYYY-MM") || ""}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="支払開始月"
              required
              type="month"
              error={!!errors.month}
              errorText={errors.month?.message}
            />
          )}
        />
        {costType === "賃貸管理費" && (
          <Controller
            name="management_cost"
            control={control}
            rules={{ required: "入力してください。" }}
            defaultValue={monthlyCashflowResult?.management_cost || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="金額"
                required
                error={!!errors.management_cost}
                errorText={errors.management_cost?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />
        )}
        {costType === "建物管理費" && (
          <Controller
            name="building_management_cost"
            control={control}
            rules={{ required: "入力してください。" }}
            defaultValue={monthlyCashflowResult?.building_management_cost || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="金額"
                required
                error={!!errors.building_management_cost}
                errorText={errors.building_management_cost?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />
        )}
        {costType === "修繕積立金" && (
          <Controller
            name="repair_fund_cost"
            control={control}
            rules={{ required: "入力してください。" }}
            defaultValue={monthlyCashflowResult?.repair_fund_cost || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="金額"
                required
                error={!!errors.repair_fund_cost}
                errorText={errors.repair_fund_cost?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />
        )}
        {costType === "その他経費" && (
          <Controller
            name="misc_cost"
            control={control}
            rules={{ required: "入力してください。" }}
            defaultValue={monthlyCashflowResult?.misc_cost || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="金額"
                required
                error={!!errors.misc_cost}
                errorText={errors.misc_cost?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />
        )}
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Typography variant="button" color="primary" className={classes.textLink} onClick={onCancel}>
          キャンセル
        </Typography>
        <Typography variant="button" color="primary" className={classes.textLink} onClick={handleSave}>
          保存
        </Typography>
      </Box>
    </Box>
  );
};
